import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import Form from "./form";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { deleteLicenceTypeAction } from "@icarius-pages/issueTypesSettings/actions";
import { getDataArrays } from "@icarius-pages/issueTypesSettings/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const { data, color, closeNew } = props;
  const dispatch = useDispatch();
  const dataArrays = useSelector(getDataArrays);

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      dispatch(deleteLicenceTypeAction(data))
    }
  }

  const handleClosePublication = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const getTypeName = () => {
    if (!dataArrays?.types) return "";
    return dataArrays.types.filter(type => type.key === data.type)[0].value;
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: "Eliminar tipo de licencia médica",
      msg: "¿Desea eliminar el tipo de licencia médica?",
      onConfirm: handleDeletePublication,
    }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={"Creación de tipos de licencia médica"} />
                :
                <>
                  <DataContainer sm={12} md={6} lg={4} text={data.code} />
                  <DataContainer sm={12} md={6} lg={4} text={data.name} />
                  <DataContainer sm={12} md={6} lg={4} text={getTypeName()} />
                </>
            }
          </Grid>

          <Grid container direction="row" item xs={1} justify={isCreate ? "flex-end" : "space-between"}>
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form data={data} dataArrays={dataArrays} handleClose={isCreate ? closeNew : handleClosePublication} />
      }
    </>
  );
}

export default DataItem;
