import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { CloseIcon } from "@icarius-icons/index";
import { createAggridColumn } from "@icarius-table/utils";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import CommonPage from "@icarius-common/commonPage";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "95%",
  },
})(Dialog);

const GridDialog = (props) => {

  const {
    open,
    data,
    types,
    title,
    dateFormat,
    locale,
    handleClose,
  } = props;

  const generateColumns = () => {
    let columnDefinitions = [];
    data.forEach((object) => {
      Object.keys(object).forEach((key) => {
        let item = {
          cellClass: "stringType",
          ...createAggridColumn(key, types[key] || ''),
        };

        columnDefinitions.push(item);
      });
    });

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) => index === self.findIndex((colAtIndex) => colAtIndex.field === column.field));

    return columnDefinitions;
  }

  return (
    <StyledDialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
    >
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <CommonPage
          gridTitle={getLocalizedString("kpiIndicators")}
          ownColumnDef={generateColumns()}
          rowData={data}
          dateFormat={dateFormat}
          locale={locale}
          gridHeight={'70vh'}
          ignoreProcessesbyPath
          hasSelectAll
          hasExpand
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default GridDialog;