import * as actionTypes from "./actionTypes";
import { getKPIsAPI, getKPIgridDataAPI, getKPIAdditionalDataAPI } from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";

export const getKpiIndicatorsFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_KPI_INDICATORS });
  try {
    let response = await getKPIsAPI();
    let kpiIndicators = response.data && response.data.result;
    let groupsToFilter = response.data && response.data.groupsToFilter;

    dispatch({
      type: actionTypes.GET_KPI_INDICATORS_FULFILLED,
      payload: {
        kpiIndicators,
        groupsToFilter
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_KPI_INDICATORS_REJECTED, payload: e });
    dispatch(openSnackbarAction({
      msg: e.response.data.status === "INVALID_DATA" ? "No se ha obtenido información" : getErrorStringFromError(e),
      severity: "error",
    }));
  }
};

export const getKPIAdditionalDataAction = (inputData, previousLevelData) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_LEVEL_DATA });
  try {
    const {
      code,
      firstLevelCategory,
      firstLevelCategoryCode,
      secondLevelCategory,
      secondLevelCategoryCode,
      level,
    } = inputData;

    let requestString = `?code=${code}&level=${level}`;
    let firstLevelData = {}

    if (firstLevelCategoryCode) {
      requestString += `&firstLevelCategoryCode=${firstLevelCategoryCode}`;
      firstLevelData.firstLevelCategoryCode = firstLevelCategoryCode;
    }

    if (firstLevelCategory) {
      requestString += `&firstLevelCategory=${firstLevelCategory}`;
      firstLevelData.firstLevelCategory = firstLevelCategory;
    }

    if (secondLevelCategoryCode) {
      requestString += `&secondLevelCategoryCode=${secondLevelCategoryCode}`;
    }

    if (secondLevelCategory) {
      requestString += `&secondLevelCategory=${secondLevelCategory}`;
    }

    let response = await getKPIAdditionalDataAPI(requestString);

    dispatch({
      type: actionTypes.GET_LEVEL_DATA_FULFILLED,
      payload: {
        nextLevelData: {
          ...previousLevelData,
          title: response.data.title,
          data: response.data.data,
          codes: response.data.codes
        },
        level,
        previousLevelData,
        firstLevelData,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_LEVEL_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({
      msg: e.response.data.status === "INVALID_DATA" ? "No se ha obtenido información" : getErrorStringFromError(e),
      severity: "error",
    }));
  }
};

export const getKPIgridDataAction = (code, employeeCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_KPI_GRID_DATA });
  try {
    let response = await getKPIgridDataAPI(`?code=${code}${employeeCode ? `&employeeCode=${employeeCode}` : ''}`);

    dispatch({
      type: actionTypes.GET_KPI_GRID_DATA_FULFILLED,
      payload: {
        data: response.data.result,
        types: response.data.types,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_KPI_GRID_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({
      msg: e.response.data.status === "INVALID_DATA" ? "No se ha obtenido información" : getErrorStringFromError(e),
      severity: "error",
    }));
  }
};