import React, { useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { createIssueVacationAction, updateIssueVacationAction } from "../../../actions";
import { getSelects } from "../../../selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const { open, loading, data, presetEmployee, handleClose, handleOpenBalanceDialog, startHolidaysOnNonWorkingDay, allowHalfDayHolidays, additionalLegalDays } = props;

  const dispatch = useDispatch();
  const selects = useSelector(getSelects);

  const { employees, vacationTypes, timePerDayTypes } = selects;

  const create = (dataToSend) => {
    dispatch(createIssueVacationAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateIssueVacationAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const getAmountOfDaysArray = () => {
    const auxArr = [];
    if (!formData['Código de empleado'] || !formData['Tipo de vacaciones']) return auxArr;
    let amountOfDays = formData['Código de empleado'][formData['Tipo de vacaciones']];

    if (formData['Tipo de vacaciones'] === "UV") {
      amountOfDays += additionalLegalDays;
    }

    for (let i = 1; i <= amountOfDays; i++) {
      auxArr.push({ key: i, value: i });
    }

    return auxArr;
  }

  const { isCreate, formData, setFormValue, submit } = useHandleForm(data, create, modify, openValidationError, selects, presetEmployee, startHolidaysOnNonWorkingDay, allowHalfDayHolidays);

  const shouldShowBalanceButton = formData['Código de empleado'];

  const filteredEmployees = useMemo(() => {
    if (!employees) return [];
    if (presetEmployee) return employees.filter(item => item.key === presetEmployee);
    return employees;
  }, [employees, presetEmployee])

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear vacaciones" : "Editar vacaciones"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <Autocomplete
                disabled={!isCreate}
                style={{ width: '100%' }}
                options={filteredEmployees}
                value={formData['Código de empleado']}
                onChange={(event, newUser) => {
                  setFormValue(newUser, 'Código de empleado');
                }}
                noOptionsText={''}
                getOptionLabel={(user) => user.value}
                renderInput={(params) => <TextField required {...params} label={'Empleado'} />}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type`}>{"Tipo de vacaciones"}</InputLabel>
                <Select
                  disabled={!isCreate}
                  value={formData['Tipo de vacaciones']}
                  labelId={`label-type`}
                  id={`select-type`}
                  onChange={(e) => setFormValue(e.target.value, 'Tipo de vacaciones')}
                  margin={"none"}
                >
                  {
                    vacationTypes.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={!isCreate}
                  fullWidth
                  required
                  label={'Fecha desde'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha desde']}
                  onChange={(e) => setFormValue(e, 'Fecha desde')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <FormControl required style={{ width: "100%" }}>
                <InputLabel id={`label-amount`}>{'Cantidad de días'}</InputLabel>
                <Select
                  disabled={!isCreate}
                  value={formData['Cantidad de días']}
                  labelId={`label-amount`}
                  id={`select-amount`}
                  onChange={(e) => setFormValue(e.target.value, 'Cantidad de días')}
                  margin={"none"}
                >
                  {
                    getAmountOfDaysArray().map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  disabled
                  label={'Fecha hasta'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha hasta']}
                  onChange={(e) => setFormValue(e, 'Fecha hasta')}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            {
              formData['Código de empleado'] && formData['Tipo de vacaciones'] && formData['Tipo de vacaciones'] === "UV" &&
              <>
                <Grid container item alignItems="center" xs={4} style={{ paddingLeft: "0px 10px" }} />
                <Grid container item alignItems="center" xs={8} style={{ padding: "0px 10px" }}>
                  <Typography
                    style={{ paddingTop: 5, color: 'red', fontWeight: 700 }}
                    variant="caption"
                    gutterBottom>
                    {`Saldo disponible en cuenta corriente: ${formData['Código de empleado'][formData['Tipo de vacaciones']]}`}
                  </Typography>
                </Grid>
              </>
            }

            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={!isCreate}
                  fullWidth
                  required
                  label={'Fecha desde de aplicación'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha desde de aplicación']}
                  onChange={(e) => setFormValue(e, 'Fecha desde de aplicación')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  disabled
                  label={'Fecha hasta de aplicación'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha hasta de aplicación']}
                  onChange={(e) => setFormValue(e, 'Fecha hasta de aplicación')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-timeperday`}>{"Tiempo por día"}</InputLabel>
                <Select
                  disabled={!isCreate || !allowHalfDayHolidays}
                  value={formData['Tiempo por día']}
                  labelId={`label-timeperday`}
                  id={`select-timeperday`}
                  onChange={(e) => setFormValue(e.target.value, 'Tiempo por día')}
                  margin={"none"}
                >
                  {
                    timePerDayTypes.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  disabled
                  label={'Fecha de retorno'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha de retorno']}
                  onChange={(e) => setFormValue(e, 'Fecha de retorno')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={8} style={gridStyle}>
              <TextField
                label={'Comentarios'}
                fullWidth
                margin={"none"}
                value={formData['Comentarios']}
                onChange={(e) => setFormValue(e.target.value, "Comentarios")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ padding: 10, visibility: shouldShowBalanceButton ? 'visible' : 'hidden' }}>
              <Typography className="whiteText" style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={() => handleOpenBalanceDialog(null, formData['Código de empleado'].key)}>
                {'Ver saldos'}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;
