import React from "react";
import { Typography } from "@material-ui/core";

function Message(props) {
  return (
    <Typography
      variant={props.variant || "subtitle1"}
      style={{
        textAlign: "center",
        wordWrap: "break-word",
        fontWeight: "bold",
      }}
      className={props.class}
      // gutterBottom
    >
      {props.text}
    </Typography>
  );
}

export default Message;
