import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,  
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createAbsenceTypeAction, modifyAbsenceTypeAction } from "@icarius-pages/issueTypesSettings/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, dataArrays, handleClose } = props;
  const dispatch = useDispatch();

  const { types, units, ids, motives, classList } = dataArrays;

  const create = (dataToSend) => {
    dispatch(createAbsenceTypeAction(dataToSend))
    .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (modifyData) => {
    const dataToSend = { ...modifyData, internalCode: data.internalCode };
    dispatch(modifyAbsenceTypeAction(dataToSend))
    .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}            
            label={"Código"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={5} style={gridStyle}> {/* nombre */}
          <TextField    
            required
            fullWidth
            margin={"none"}            
            label={"Nombre"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>    
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* type */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-type-${data ? data.code : "new"}`}>{"Tipo"}</InputLabel>
            <Select      
              disabled={formData.unit === "H"}  
              value={formData.type}
              labelId={`label-type-${data ? data.code : "new"}`}
              id={`select-type-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "type")}
              margin={"none"}
            >
              {
                types && types.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* unit */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-unit-${data ? data.code : "new"}`}>{"Unidad"}</InputLabel>
            <Select        
              value={formData.unit}
              labelId={`label-unit-${data ? data.code : "new"}`}
              id={`select-unit-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "unit")}
              margin={"none"}
            >
              {
                units && units.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* ids */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-ids-${data ? data.code : "new"}`}>{"ID"}</InputLabel>
            <Select        
              value={formData.id}
              labelId={`label-ids-${data ? data.code : "new"}`}
              id={`select-ids-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "id")}
              margin={"none"}
            >
              {
                ids && ids.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* motive */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-motive-${data ? data.code : "new"}`}>{"Ausencia por capacitación o cursos"}</InputLabel>
            <Select        
              value={formData.motive}
              labelId={`label-motive-${data ? data.code : "new"}`}
              id={`select-motive-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "motive")}
              margin={"none"}
            >
              {
                motives && motives.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={12} md={5} style={gridStyle}> {/* Descripción para el directorio */}
          <TextField    
            required={formData.unit === "D"}
            fullWidth
            margin={"none"}            
            label={"Descripción para el directorio"}
            value={formData.description}
            onChange={(e) => setFormValue(e.target.value, "description")}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* class */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-classType-${data ? data.code : "new"}`}>{"Clase"}</InputLabel>
            <Select      
              value={formData.classType}
              labelId={`label-classType-${data ? data.code : "new"}`}
              id={`select-classType-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "classType")}
              margin={"none"}
            >
              {
                classList && classList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>        
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>      
    </Grid>
  );
}

export default Form;
