import React, { useRef, useEffect, useMemo } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useIssuePermissions from "./useIssuePermissions";
import CreateEditDialog from "./dialogs/createEditDialog/index";
import useAutomaticProcesses from "@icarius-common/automaticProcesses/useAutomaticProcesses";
import ResultGridDialog from "@icarius-common/automaticProcesses/components/resultGridDialog";
import PickTemplateDialog from "@icarius-pages/employeesProcesses/components/dialogs/pickTemplateDialog";
import { PrintIcon, VisibilityIcon } from "@icarius-icons/index";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import UploadFileDialog from "./dialogs/uploadFileDialog";
import { getUserRole } from "src/app/selectors";
import ViewBalanceDialog from "@icarius-pages/myRequests/components/viewBalanceDialog";
import { useHistory, useLocation } from "react-router-dom";
import SelectProcessDialog from "@icarius-common/automaticProcesses/components/selectProcessDialog";

const IssuePermissions = () => {

  const dispatch = useDispatch();
  const role = useSelector(getUserRole);

  const location = useLocation();
  const history = useHistory();

  const ownGridRef = useRef();
  const hasSetFilters = useRef(false);

  const {
    data,
    isLoading,
    state,
    handlers
  } = useIssuePermissions();

  const {
    isExecution,
    automaticProcessesIsLoading,
    automaticProcessesIsEnabled,
    processList,
    resultData,
    resultDialogIsOpen,
    pickTemplateDialogIsOpen,
    processSelectionDialogIsOpen,
    executeProcess,
    handleExecute,
    viewResult,
    handleViewResult,
    handleCloseProcessDialogSelection,
    handleCloseResultDialog,
    handleOpenPickTemplateDialog,
    handleClosePickTemplateDialog,
    handleGenerateDocumentsGivenTemplate,
  } = useAutomaticProcesses(role === "M");

  useEffect(() => {
    if (data && ownGridRef.current && location?.state && !Boolean(hasSetFilters.current)) {
      ownGridRef.current.api.setFilterModel({
        "Fecha desde": { type: 'iguales', filter: location?.state.date },
      })

      hasSetFilters.current = true;
    }
  }, [data, location])

  const handleValidateAndExecute = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    const employeeCodes = selectedRows.map(item => item["Código de empleado"]);
    handleExecute(employeeCodes);
  }

  const handleValidateAndViewResult = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    handleViewResult(selectedRows[0], selectedRows[0]["Código de empleado"]);
  }

  const executeItem = (gridRef) => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={`Calcular${processList.length === 1 ? `: ${processList[0].value}` : ' proceso'}`}
      onClick={() => handleValidateAndExecute(gridRef)}
      type={"execute"}
    />
  )

  const viewResultItem = (gridRef) => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={"Ver resultado"}
      onClick={() => handleValidateAndViewResult(gridRef)}
    >
      <VisibilityIcon />
    </CustomIconButton>
  )

  const generateItem = (gridRef) => (
    <CustomIconButton
      title={'Generar comprobante'}
      onClick={() => handlers.handleGenerateFile(gridRef)}
    >
      <PrintIcon />
    </CustomIconButton>
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const exportButton = () =>
    <CustomIconButton
      title={"Exportar plantilla para importación"}
      onClick={handlers.handleDownloadStructure}
      type={"download"}
    />

  const importButton = () =>
    <CustomIconButton
      title={"Importar permisos"}
      onClick={handlers.handleOpenUploadFileDialogIsOpen}
      type={"upload"}
    />

  const goBackButton = () => (
    <CustomIconButton
      title={'Volver'}
      type={'goBack'}
      onClick={() => history.push(Boolean(location?.state?.from) ? location?.state.from : paths.issueManagementScreenSelector)}
    />
  )

  const filteredData = useMemo(() => {
    if (!data) return [];
    if (!Boolean(location?.state?.code)) return data;
    return data.filter(item => item['Código de empleado'] === location.state?.code);
  }, [data, location])

  return (
    <CommonPage
      isLoading={isLoading || automaticProcessesIsLoading}
      title={`Gestión de permisos${Boolean(location?.state?.name) ? ` - ${location.state.name}` : ''}`}
      gridTitle={`Gestión de permisos${Boolean(location?.state?.name) ? ` - ${location.state.name}` : ''}`}
      columnDefPage={paths.issuePermissions}
      rowData={filteredData}
      menuItems={[goBackButton, createButton, modifyButton, deleteButton, exportButton, importButton, generateItem, executeItem, viewResultItem]}
      hasExpand
      hasHelp
      ownGridRef={ownGridRef}
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          loading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDialog}
          handleOpenBalanceDialog={handlers.handleOpenBalanceDialog}
          presetEmployee={location?.state?.code}
        />
      }
      {
        processSelectionDialogIsOpen &&
        <SelectProcessDialog
          open={processSelectionDialogIsOpen}
          title={isExecution ? "Selección de proceso a ejecutar" : "Selección de proceso a consultar"}
          processList={processList}
          handleClose={handleCloseProcessDialogSelection}
          handleExecute={isExecution ? executeProcess : viewResult}
        />
      }
      {
        resultDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
        <ResultGridDialog
          open={resultDialogIsOpen}
          templates={resultData.templates}
          process={resultData.process}
          employeeName={resultData.employee['Apellido y nombres']}
          handleClose={handleCloseResultDialog}
          handleOpenPickTemplateDialog={handleOpenPickTemplateDialog}
          handleGenerateDocumentsGivenTemplate={handleGenerateDocumentsGivenTemplate}
        />
      }
      {
        resultData?.templates?.length > 0 && pickTemplateDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
        <PickTemplateDialog
          open={pickTemplateDialogIsOpen}
          templates={resultData.templates}
          handleConfirm={handleGenerateDocumentsGivenTemplate}
          handleClose={handleClosePickTemplateDialog}
        />
      }
      {
        state.uploadFileDialogIsOpen &&
        <UploadFileDialog
          open={state.uploadFileDialogIsOpen}
          isLoading={isLoading}
          handleClose={handlers.handleCloseUploadFileDialogIsOpen}
        />
      }
      {
        state.balanceDialogIsOpen &&
        <ViewBalanceDialog
          open={state.balanceDialogIsOpen}
          data={state.balanceData}
          handleClose={handlers.handleCloseBalanceDialog}
        />
      }
    </CommonPage>
  )
}

export default IssuePermissions;