import { useState } from "react";
import * as yup from 'yup';
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, openSelectorValidationError) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "active") {
        return isCreate ? "Y" : data[fieldName];
      }

      if (fieldName === "selectors") {
        return isCreate ? "" : data[fieldName].join(",");
      }

      if (fieldName === "tempStartDate") {
        return isCreate ? new Date() : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === "tempEndDate") {
        if (isCreate) return new Date();
        if (data[fieldName] === '31/12/9999') {
          return createDateFromDDMMYYYY('31/12/2099');
        }
        return createDateFromDDMMYYYY(data[fieldName]);
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'code',
      'active',
      'name',
      'group',
      'sequence',
      'kpiType',
      'description',
      'tempStartDate',
      'tempEndDate',
      'rol',
      'sqlTitle',
      'sqlTitleLevel2',
      'sqlChartLevel2',
      'sqlTitleLevel3',
      'sqlChartLevel3',
      'sqlSubtitle',
      'sqlNumber',
      'clockMinimumValue',
      'clockMaximumValue',
      'clockMinumumGreenValue',
      'clockMaximumGreenValue',
      'clockMinimumYellowValue',
      'clockMaximumYellowValue',
      'clockMinimumRedValue',
      'clockMaximumRedValue',
      'icon1',
      'icon1MinimumValue',
      'icon1MaximumValue',
      'icon2',
      'icon2MinimumValue',
      'icon2MaximumValue',
      'icon3',
      'icon3MinimumValue',
      'icon3MaximumValue',
      'sqlChart',
      'sqlVisualization',
      'selectors',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());


  const validateData = async () => {

    const codeRegex = /^[A-Z]+[A-Z0-9_]*$/;
    const numberStringRegex = /^\d*$/;

    const schema = yup.object().shape({
      code: yup.string().matches(codeRegex).max(10).required(),
      name: yup.string().max(60).required(),
      active: yup.string().required(),
      group: yup.string().max(100).required(),
      sequence: yup.number().max(999).required(),
      kpiType: yup.string().required(),
      description: yup.string().max(254).required(),
      tempStartDate: yup.date().required(),
      tempEndDate: yup.date().required(),
      rol: yup.string().required(),
      sqlTitle: yup.string(),
      sqlTitleLevel2: yup.string(),
      sqlChartLevel2: yup.string(),
      sqlTitleLevel3: yup.string(),
      sqlChartLevel3: yup.string(),
      sqlSubtitle: yup.string(),
      sqlNumber: yup.string(),
      sqlChart: yup.string(),
      sqlVisualization: yup.string(),
      selectors:yup.string()
      .when('sqlVisualization', {
        is: (sqlVisualization) => sqlVisualization.length > 0,
        then: yup.string().required()
      }),
      icon1: yup.string().max(254),
      icon2: yup.string().max(254),
      icon3: yup.string().max(254),
      clockMinimumValue: yup.string().matches(numberStringRegex),
      clockMaximumValue: yup.string().matches(numberStringRegex),
      clockMinumumGreenValue: yup.string().matches(numberStringRegex),
      clockMaximumGreenValue: yup.string().matches(numberStringRegex),
      clockMinimumYellowValue: yup.string().matches(numberStringRegex),
      clockMaximumYellowValue: yup.string().matches(numberStringRegex),
      clockMinimumRedValue: yup.string().matches(numberStringRegex),
      clockMaximumRedValue: yup.string().matches(numberStringRegex),
      icon1MinimumValue: yup.string().matches(numberStringRegex),
      icon1MaximumValue: yup.string().matches(numberStringRegex),
      icon2MinimumValue: yup.string().matches(numberStringRegex),
      icon2MaximumValue: yup.string().matches(numberStringRegex),
      icon3MinimumValue: yup.string().matches(numberStringRegex),
      icon3MaximumValue: yup.string().matches(numberStringRegex),
    });

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        const validationErrors = {};
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          return false;
        }
      });
  }

  const selectorsAreValid = () => {
    if (!Boolean(formData.sqlVisualization) && !Boolean(formData.selectors)) return true;
    const selectors = formData.selectors.split(",");
    const validSelectors = [
      "Lista",
      "Fecha",
      "Alfa",
      "Num",
      "Mon",
      "List",
      "Date"
    ];

    return selectors.every(selector => validSelectors.includes(selector));
  }

  const submit = async () => {
    const validatedData = await validateData();

    if (Boolean(validatedData)) {
      if (selectorsAreValid()) {
        const formDataToSend = { ...validatedData };
        const formattedEndDate = formatDateYYYYMMDD(formDataToSend.tempEndDate);

        formDataToSend.tempStartDate = formatDateYYYYMMDD(formDataToSend.tempStartDate);
        formDataToSend.tempEndDate = formattedEndDate === '2099/12/31' ? '9999/12/31' : formattedEndDate;
        formDataToSend.selectors = Boolean(formDataToSend.selectors) ? formDataToSend.selectors.split(",") : [];
        formDataToSend.sequence = parseInt(formDataToSend.sequence);
        formDataToSend.clockMinimumValue = parseInt(formDataToSend.clockMinimumValue);
        formDataToSend.clockMaximumValue = parseInt(formDataToSend.clockMaximumValue);
        formDataToSend.clockMinumumGreenValue = parseInt(formDataToSend.clockMinumumGreenValue);
        formDataToSend.clockMaximumGreenValue = parseInt(formDataToSend.clockMaximumGreenValue);
        formDataToSend.clockMinimumYellowValue = parseInt(formDataToSend.clockMinimumYellowValue);
        formDataToSend.clockMaximumYellowValue = parseInt(formDataToSend.clockMaximumYellowValue);
        formDataToSend.clockMinimumRedValue = parseInt(formDataToSend.clockMinimumRedValue);
        formDataToSend.clockMaximumRedValue = parseInt(formDataToSend.clockMaximumRedValue);
        formDataToSend.icon1MinimumValue = parseInt(formDataToSend.icon1MinimumValue);
        formDataToSend.icon1MaximumValue = parseInt(formDataToSend.icon1MaximumValue);
        formDataToSend.icon2MinimumValue = parseInt(formDataToSend.icon2MinimumValue);
        formDataToSend.icon2MaximumValue = parseInt(formDataToSend.icon2MaximumValue);
        formDataToSend.icon3MinimumValue = parseInt(formDataToSend.icon3MinimumValue);
        formDataToSend.icon3MaximumValue = parseInt(formDataToSend.icon3MaximumValue);

        isCreate ? createCallback(formDataToSend) : modifyCallback(formDataToSend);
        return true;
      }
      openSelectorValidationError();
      return false;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    if (fieldName === "sequence") {
      return value.length > 0 ? value.replace(/\s/g, "") : "";
    }

    if (fieldName === "selectors") {
      if (value.length === 0) return "";
      if (value.charAt(0) === ",") {
        return value.substring(1);
      }
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
