import React from "react";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import useIssueSummary from "./useIssueSummary";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import MatrixDialog from "./dialogs/matrixDialog";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";

const IssueSummary = ({ code, name, handleGoBack, history }) => {

  const comesFromOtherPage = Boolean(code);

  const {
    isLoading,
    data,
    selectedRow,
    matrixData,
    yearList,
    issueList,
    matrixDialogIsOpen,
    handleRowClick,
    handleOpenMatrixDialog,
    handleCloseMatrixDialog,
    handleOpenCalendarDialog,
  } = useIssueSummary(code);

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('ISSUE_SUMMARY');

  const selectInput = (gridRef) => (
    <TemplatesHeader
      gridRef={gridRef}
      user={user}
      templates={templates}
      selectedTemplate={selectedTemplate}
      getRole={getRole}
      handleImportTemplates={handleImportTemplates}
      handleExportTemplates={handleExportTemplates}
      handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
      handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
      handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
      handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
    />
  )

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
      {
        matrixDialogIsOpen &&
        <MatrixDialog
          open={matrixDialogIsOpen}
          handleClose={handleCloseMatrixDialog}
          data={matrixData}
          yearList={yearList}
          issueList={issueList}
          selectedRow={selectedRow}
        />
      }
    </>

  const calendarItem = (gridRef) => (
    <CustomIconButton
      title={"Ver calendario"}
      onClick={() => handleOpenCalendarDialog(gridRef)}
      type={"calendar"}
    />
  )

  const matrixItem = (gridRef) =>
    <CustomIconButton
      title={'Matriz de incidencias'}
      onClick={() => handleOpenMatrixDialog(gridRef)}
    />

  const goBackItem = () =>
    <CustomIconButton
      title={'Volver'}
      onClick={() => Boolean(handleGoBack) ? handleGoBack() : history.push(paths.issueManagementScreenSelector)}
      type={"goBack"}
    />

  return (
    <CommonPage
      isLoading={isLoading || isLoadingTemplates}
      title={`Resumen de incidencias ${name ? `- ${name}` : ''}`}
      gridTitle={`Resumen de incidencias ${name ? `- ${name}` : ''}`}
      columnDefPage={paths.issueSummary}
      rowData={data}
      handleRowClick={!comesFromOtherPage && handleRowClick}
      customHeader={!comesFromOtherPage && selectInput}
      menuItems={comesFromOtherPage ? [goBackItem, matrixItem] : [goBackItem, calendarItem, matrixItem]}
      hasExpand
      hasHelp
      codeFieldName={'Código de empleado'}
    >
      {child}
    </CommonPage>
  )
}

export default IssueSummary;