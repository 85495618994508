import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { getTheme } from "@icarius-pages/login/selectors";
import { getKPIgridDataAction, getKPIAdditionalDataAction } from "../actions";
import {
  getDateFormat,
  getGridData,
  getLocale,
  getIsLoading,
  getGridTypes,
  getFirstLevelData,
  getSecondLevelData,
  getThirdLevelData,
  getKpiIndicators,
  getGroupsToFilter,
} from "../selectors";
import { GO_BACK_ONE_LEVEL } from "../actionTypes";

const useKPI = (employeeCode) => {

  const [gridModalIsOpen, setGridModalIsOpen] = useState(false);
  const [chartTitle, setChartTitle] = useState('');

  const dispatch = useDispatch();

  const isLoading = useSelector(getIsLoading);
  const kpiIndicators = useSelector(getKpiIndicators);
  const groupsToFilter = useSelector(getGroupsToFilter);
  const firstLevelData = useSelector(getFirstLevelData);
  const secondLevelData = useSelector(getSecondLevelData);
  const thirdLevelData = useSelector(getThirdLevelData);

  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const gridData = useSelector(getGridData);
  const gridTypes = useSelector(getGridTypes);

  const color = useSelector(getAppColor);
  const gridTheme = useSelector(getTheme);

  const handleCloseDialog = () => {
    setGridModalIsOpen(false);
    setChartTitle("");
  }

  const handleChartClick = (code, title) => {
    dispatch(getKPIgridDataAction(code, employeeCode))
      .then(() => {
        setGridModalIsOpen(true);
        if (secondLevelData.length > 0) {
          setChartTitle("Visualización de datos detallados");
        } else {
          setChartTitle(title);
        }
      });
  };

  const handleGoBackOneLevel = (code, level) => {
    dispatch({
      type: GO_BACK_ONE_LEVEL,
      payload: { code, level },
    });
  }

  return {
    isLoading,
    gridTheme,
    color,
    locale,
    dateFormat,
    chartTitle,
    kpiIndicators,
    groupsToFilter,
    gridModalIsOpen,
    gridTypes,
    gridData,
    firstLevelData,
    secondLevelData,
    thirdLevelData,
    handleGoBackOneLevel,
    handleChartClick,
    handleCloseDialog,
    getKPIAdditionalDataAction,
  }
}

export default useKPI;
