import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  dateFormat: "yyyy/mm/dd",
  groupsToFilter: [],
  kpiIndicators: {},
  gridData: [],
  gridTypes: [],
  firstLevelData: [],
  secondLevelData: [],
  thirdLevelData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_KPI_INDICATORS:
      return { ...state, isLoading: true };
    case actionTypes.GET_KPI_INDICATORS_FULFILLED:
      return {
        ...initialState,
        groupsToFilter: action.payload.groupsToFilter,
        kpiIndicators: action.payload.kpiIndicators,
      };
    case actionTypes.GET_KPI_INDICATORS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_KPI_GRID_DATA:
      return { ...state, isLoading: true };
    case actionTypes.GET_KPI_GRID_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        gridData: action.payload.data,
        gridTypes: action.payload.types,
        dateFormat: action.payload.date_format,
      };
    case actionTypes.GET_KPI_GRID_DATA_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_LEVEL_DATA:
      return { ...state, isLoading: true };
    case actionTypes.GET_LEVEL_DATA_FULFILLED: {

      const {
        previousLevelData,
        level,
        nextLevelData,
        firstLevelData,
      } = action.payload;
      const { group } = nextLevelData;

      let newKpiIndicators = { ...state.kpiIndicators };
      let newFirstLevelData = [...state.firstLevelData];
      let newSecondLevelData = [...state.secondLevelData];
      let newThirdLevelData = [...state.thirdLevelData];

      // pisar la data del kpi con la del nuevo level
      if (newKpiIndicators.hasOwnProperty(group)) {
        const index = newKpiIndicators[group].findIndex((item) => item.code === nextLevelData.code);
        if (index > -1) {
          let groupArray = [...newKpiIndicators[group]];
          groupArray[index] = nextLevelData;
          newKpiIndicators = { ...newKpiIndicators, [group]: groupArray };
        }
      }

      if (level === 1) {
        newFirstLevelData.push({ ...previousLevelData, ...firstLevelData });
        newSecondLevelData.push(nextLevelData);
        newThirdLevelData = newThirdLevelData.filter((item) => item.code !== nextLevelData.code);

        return {
          ...state,
          isLoading: false,
          kpiIndicators: newKpiIndicators,
          firstLevelData: newFirstLevelData,
          secondLevelData: newSecondLevelData,
          thirdLevelData: newThirdLevelData,
        };
      }

      newThirdLevelData.push(nextLevelData);

      return {
        ...state,
        isLoading: false,
        kpiIndicators: newKpiIndicators,
        thirdLevelData: newThirdLevelData,
      };
    }
    case actionTypes.GET_LEVEL_DATA_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GO_BACK_ONE_LEVEL: {
      const kpiCode = action.payload.code;
      const previousLevel = action.payload.level;

      // vengo del nivel 2, voy al 1
      if (previousLevel === 2) {
        const firstLevelData = [...state.firstLevelData].find((item) => item.code === kpiCode);
        let newKpiIndicators = { ...state.kpiIndicators };

        // pisar la data del kpi con la del level anteripr
        if (newKpiIndicators.hasOwnProperty(firstLevelData.group)) {
          const index = newKpiIndicators[firstLevelData.group].findIndex((item) => item.code === kpiCode);
          if (index > -1) {
            let groupArray = [...newKpiIndicators[firstLevelData.group]];
            groupArray[index] = firstLevelData;
            newKpiIndicators = { ...newKpiIndicators, [firstLevelData.group]: groupArray };
          }
        }

        let newFirstLevelData = [...state.firstLevelData].filter((item) => item.code !== kpiCode);
        let newSecondLevelData = [...state.secondLevelData].filter((item) => item.code !== kpiCode);
        let newThirdLevelData = [...state.thirdLevelData].filter((item) => item.code !== kpiCode);

        return {
          ...state,
          kpiIndicators: newKpiIndicators,
          firstLevelData: newFirstLevelData,
          secondLevelData: newSecondLevelData,
          thirdLevelData: newThirdLevelData,
        };
      }

      // vengo del nivel 3, voy al 2
      let kpiIndicators = { ...state.kpiIndicators };
      const secondLevelData = [...state.secondLevelData].find((item) => item.code === kpiCode);

      // pisar la data del kpi con la del nuevo level
      if (kpiIndicators.hasOwnProperty(secondLevelData.group)) {
        const index = kpiIndicators[secondLevelData.group].findIndex((item) => item.code === kpiCode);
        if (index > -1) {
          let clonedArray = [...kpiIndicators[secondLevelData.group]];
          clonedArray[index] = secondLevelData;
          kpiIndicators = { ...kpiIndicators, [secondLevelData.group]: clonedArray };
        }
      }

      let newThirdLevelData = [...state.thirdLevelData].filter((item) => item.code !== kpiCode);

      return {
        ...state,
        kpiIndicators,
        thirdLevelData: newThirdLevelData,
      };
    }

    case actionTypes.RESET_KPI_INDICATORS: {
      return initialState;
    }

    default:
      return state;
  }
}