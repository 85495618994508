import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { CloseIcon } from "@icarius-icons";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";

const MatrixDialog = (props) => {
  const {
    open,
    data,
    handleClose,
    yearList,
    issueList,
    currencyLocalization,
    selectedRow,
  } = props;

  const [year, setYear] = useState(yearList[0]);

  const getFilteredData = () => {
    let filteredData = [...data];

    if (year) {
      filteredData = filteredData.filter(item => item.year === year);
    }

    return filteredData;
  }

  const yearItem = () => (
    <FormControl style={{ width: 250, marginRight: 10 }}>
      <InputLabel id={`label-year`}>{"Año"}</InputLabel>
      <Select
        value={year}
        labelId={`label-year`}
        id={`select-year`}
        onChange={(e) => setYear(e.target.value)}
        margin={"none"}
      >
        {
          yearList.map(item => (
            <MenuItem
              className={"whiteText"}
              key={item}
              value={item}>
              {item}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )

  const issueItem = () => (
    <FormControl style={{ width: 250, marginRight: 10 }}>
      <InputLabel id={`label-issue`}>{"Tipos de inasistencia"}</InputLabel>
      <Select
        value={''}
        labelId={`label-issue`}
        id={`select-issue`}
        margin={"none"}
      >
        {
          issueList.map(item => (
            <MenuItem
              className={"whiteText"}
              key={item.key}
              value={item.key}>
              {item.value}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"xl"}
    >
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {`Matriz de incidencias - ${selectedRow?.['Apellido y nombres']}`}
      </DialogTitle>
      <DialogContent>
        <CommonPage
          menuItems={[yearItem, issueItem]}
          gridTitle={'Matriz de incidencias'}
          columnDefPage={paths.issueSummaryMatrix}
          rowData={getFilteredData()}
          locale={currencyLocalization}
          gridHeight={'60vh'}
          hasSelectAll
          hasHelp
          hasExpand
          ignoreProcessesbyPath
        />
      </DialogContent>
    </Dialog>
  );
}

export default MatrixDialog;