import React from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { speedometerColors } from "@icarius-utils/colors";
import Message from "./Message";

class Speedometer extends React.PureComponent {

  render() {
    const {
      data,
      theme,
      handleChartClick,
    } = this.props;

    const clockRanges = data.clock_ranges;

    return (
      <div className={"container h-100 sm:h-100 relative flex flex-col justify-center items-center"}>
        <div className="flex-col">
          {
            <Message
              text={data.title || getLocalizedErrorString("invalidTitle")}
              class={data.title ? "whiteText" : "errorColor"}
            />
          }
        </div>
        <div className="flex-col">
          <Message text={data.subtitle || ""} class={"whiteText"} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: data.has_grid ? "pointer" : "",
          }}
          onClick={data.has_grid ? () => handleChartClick(data.code, data.title) : null}
          className="principal chart-wrapper"
        >
          {
            Boolean(data.data) ?
              <ReactSpeedometer
                maxValue={clockRanges.maximum_red}
                value={parseInt(data.data)}
                segments={3}
                customSegmentStops={[
                  0,
                  clockRanges.minimum_yellow,
                  clockRanges.minimum_red,
                  clockRanges.maximum_red,
                ]}
                needleColor={"#CCC"}
                segmentColors={speedometerColors}
                height={200}
                needleTransition="easeCircleInOut"
                textColor={theme.theme === "dark" ? "white" : "black"}
                needleTransitionDuration={0}
              />
              :
              <Message class="errorColor" text={'Sin datos actuales para graficar'} />
          }
        </div>
      </div>
    );
  }
}

export default Speedometer;