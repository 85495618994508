import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberComparatorMax3,
	numberFormatterMin2,
	numberFormatterMin0Max3,
	numberFilterParams,
	numberFilterParamsWithThreeDigits,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: 'Mes',
            field: "Mes",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '1',
            field: "1",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '2',
            field: "2",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '3',
            field: "3",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '4',
            field: "4",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '5',
            field: "5",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '6',
            field: "6",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '7',
            field: "7",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '8',
            field: "8",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '9',
            field: "9",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '10',
            field: "10",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '11',
            field: "11",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '12',
            field: "12",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '13',
            field: "13",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '14',
            field: "14",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '15',
            field: "15",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '16',
            field: "16",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '17',
            field: "17",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '18',
            field: "18",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '19',
            field: "19",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '20',
            field: "20",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '21',
            field: "21",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '22',
            field: "22",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '23',
            field: "23",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '24',
            field: "24",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '25',
            field: "25",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '26',
            field: "26",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '27',
            field: "27",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '28',
            field: "28",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '29',
            field: "29",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '30',
            field: "30",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummaryMatrix],
        config: {
            headerName: '31',
            field: "31",
            filter: "agSetColumnFilter",
            width: 74,
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Código de empleado',
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Apellido y nombres',
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Origen',
            field: "Origen",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Año de inicio',
            field: "Año de inicio",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Período de inicio',
            field: "Período de inicio",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Fecha desde',
            field: "Fecha desde",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Cantidad de días',
            field: "Cantidad de días",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Fecha hasta',
            field: "Fecha hasta",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Fecha desde de aplicación',
            field: "Fecha desde de aplicación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Fecha hasta de aplicación',
            field: "Fecha hasta de aplicación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Tipo de incidencia',
            field: "Tipo de incidencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Unidad',
            field: "Unidad",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config:
        {
            headerName: 'Tiempo por día',
            field: "Tiempo por día",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.issueSummary],
        config:
        {
            headerName: 'Cantidad total',
            field: "Cantidad total",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Referencia de tiempo',
            field: "Referencia de tiempo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'ID',
            field: "ID",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Grupo',
            field: "Grupo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueSummary],
        config: {
            headerName: 'Comentario',
            field: "Comentario",
            filter: "agTextColumnFilter",
        }
    },
]