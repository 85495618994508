import React, { useEffect } from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Loader from "@icarius-common/loader";
import { useDispatch } from "react-redux";
import {
  GET_KPI_INDICATORS_FULFILLED,
  RESET_KPI_INDICATORS,
} from "../actionTypes";
import Charts from "./charts";
import GridDialog from "./gridDialog";
import useKPI from "./useKPI";

const IndividualKPIDialog = (props) => {

  const {
    open,
    data,
    employeeCode,
    handleClose,
  } = props;

  const {
    isLoading,
    gridTheme,
    color,
    locale,
    dateFormat,
    chartTitle,
    kpiIndicators,
    groupsToFilter,
    gridModalIsOpen,
    gridTypes,
    gridData,
    firstLevelData,
    secondLevelData,
    thirdLevelData,
    handleGoBackOneLevel,
    handleChartClick,
    handleCloseDialog,
    getKPIAdditionalDataAction,
  } = useKPI(employeeCode);

  const dispatch = useDispatch();

  useEffect(() => {
    // guardarlo en el reducer de kpi (para consumirlo de ahi cuando despues se pisa con la data de los proximos niveles)
    dispatch({
      type: GET_KPI_INDICATORS_FULFILLED,
      payload: {
        kpiIndicators: { [data.group]: [data] },
      }
    });

    return () => dispatch({ type: RESET_KPI_INDICATORS });
  }, [dispatch, data])

  const kpi = kpiIndicators[data.group];

  if (!kpi) return null;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"md"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
            {data.title}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent style={{ padding: 0 }}>
            <Loader open={isLoading} />
            <Charts
              individual
              color={color}
              theme={gridTheme}
              data={{ [data.group]: kpi }}
              groupsToFilter={groupsToFilter}
              firstLevelData={firstLevelData}
              secondLevelData={secondLevelData}
              thirdLevelData={thirdLevelData}
              handleChartClick={handleChartClick}
              getKPIAdditionalDataAction={getKPIAdditionalDataAction}
              handleGoBack={handleGoBackOneLevel}
            />
          </DialogContent>
        </div>
      </Dialog>
      {
        Object.keys(gridData).length > 0 && !isLoading &&
        <GridDialog
          open={gridModalIsOpen}
          data={gridData}
          types={gridTypes}
          title={chartTitle}
          locale={locale}
          dateFormat={dateFormat || "dd/mm/yyyy"}
          handleClose={handleCloseDialog}
        />
      }
    </>
  );
}

export default IndividualKPIDialog;