import React, { useEffect } from "react";
import { Typography, List, Grid, ListItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import useFavourites from "@icarius-common/favouriteButton/components/useFavourites";
import { getKpiIndicatorsFromServer } from "../actions";
import { RESET_KPI_INDICATORS } from "../actionTypes";
import GridDialog from "./gridDialog";
import Charts from "./charts";
import useKPI from "./useKPI";

const KpiIndicators = () => {

  const dispatch = useDispatch();
  const {
    isLoadingFavourites,
    favouriteList,
    handleChangeFavourite,
  } = useFavourites('KPI');


  useEffect(() => {
    dispatch(getKpiIndicatorsFromServer());

    return () => {
      dispatch({ type: RESET_KPI_INDICATORS });
    }
  }, [dispatch])

  const {
    isLoading,
    gridTheme,
    color,
    locale,
    dateFormat,
    chartTitle,
    kpiIndicators,
    groupsToFilter,
    gridModalIsOpen,
    gridTypes,
    gridData,
    firstLevelData,
    secondLevelData,
    thirdLevelData,
    handleGoBackOneLevel,
    handleChartClick,
    handleCloseDialog,
    getKPIAdditionalDataAction,
  } = useKPI();

  return (
    <CommonPage
      isNotGridPage
      title={getLocalizedString("kpiIndicators")}
      isLoading={isLoading || isLoadingFavourites}
      hasHelp
    >
      {
        !isLoading && !kpiIndicators ?
          <List className="list-round-container">
            <ListItem>
              <Typography variant="h6" id={"toolbar-title"}>
                {getLocalizedString("noKPIdata")}
              </Typography>
            </ListItem>
          </List>
          :
          kpiIndicators &&
          <Grid id={"kpi-body"} container>
            <Charts
              color={color}
              theme={gridTheme}
              data={kpiIndicators}
              groupsToFilter={groupsToFilter}
              favouriteList={favouriteList}
              firstLevelData={firstLevelData}
              secondLevelData={secondLevelData}
              thirdLevelData={thirdLevelData}
              handleChartClick={handleChartClick}
              handleChangeFavourite={handleChangeFavourite}
              getKPIAdditionalDataAction={getKPIAdditionalDataAction}
              handleGoBack={handleGoBackOneLevel}
            />
          </Grid>
      }
      {
        Object.keys(gridData).length > 0 &&
        <GridDialog
          open={gridModalIsOpen}
          data={gridData}
          types={gridTypes}
          title={chartTitle}
          locale={locale}
          dateFormat={dateFormat || "dd/mm/yyyy"}
          handleClose={handleCloseDialog}
        />
      }
    </CommonPage>
  );
}

export default KpiIndicators;