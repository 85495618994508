import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import { AbsenceIcon, PermissionIcon, LicenceIcon } from "@icarius-icons/";
import paths from "@icarius-localization/paths";
import { AssetsAndDiscountsSummaryIcon, HolidayTableIcon } from "@icarius-icons/index";

const IssueTypeSettings = () => {

  const data = [
    {
      name: "Gestión de inasistencias",
      icon: <AbsenceIcon />,
      path: paths.issueAbsences,
    },
    {
      name: "Gestión de permisos",
      icon: <PermissionIcon />,
      path: paths.issuePermissions,
    },
    {
      name: "Gestión de licencias médicas",
      icon: <LicenceIcon />,
      path: paths.issueLicences,
    },
    {
      name: "Gestión de vacaciones",
      icon: <HolidayTableIcon />,
      path: paths.issueVacations,
    },
    {
      name: "Resumen de incidencias",
      icon: <AssetsAndDiscountsSummaryIcon />,
      path: paths.issueSummary,
    },
  ];

  return (
    <ScreenSelector
      title={'Gestión de incidencias'}
      data={data}
    />
  );
}

export default IssueTypeSettings;
