import React from "react";
import Absence from "./absence";
import Permission from "./permission";
import Licence from "./licence";
import { useLocation } from "react-router-dom";
import paths from "@icarius-localization/paths";

const IssueTypeSettings = ({ history }) => {

  const location = useLocation();

  const handleGoBack = () => history.push(paths.issueTypesSettings);

  const getScreenToShow = () => {
    if (location.pathname === paths.absenceTypes) {
      return (
        <Absence handleGoBack={handleGoBack} />
      )
    }

    if (location.pathname === paths.permissionTypes) {
      return (
        <Permission handleGoBack={handleGoBack} />
      )
    }

    if (location.pathname === paths.licenceTypes) {
      return (
        <Licence handleGoBack={handleGoBack} />
      )
    }
  }

  return (
    <section id={"my-people-page"}>
      {
        getScreenToShow()
      }
    </section>
  );
}

export default IssueTypeSettings;
