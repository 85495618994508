import { useState } from "react";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import * as yup from 'yup';
import moment from "moment";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch } from "react-redux";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, selects, presetEmployee, startHolidaysOnNonWorkingDay, allowHalfDayHolidays) => {
  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName.includes('Fecha')) {
        if (data?.[fieldName]) return createDateFromDDMMYYYY(data[fieldName]);
        if (['Fecha desde', 'Fecha desde de aplicación'].includes(fieldName)) return new Date();
        return null;
      }

      if (fieldName === 'Código de empleado') {
        if (presetEmployee) return selects.employees.find(item => item.key === presetEmployee);
        return isCreate ? null : selects.employees.find(item => item.key === data[fieldName]);
      }

      if (fieldName === 'Tiempo por día' && !allowHalfDayHolidays && isCreate) {
        return 1;
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'Código de empleado',
      'Tipo de vacaciones',
      'Fecha desde',
      'Cantidad de días',
      'Fecha hasta',
      'Fecha desde de aplicación',
      'Fecha hasta de aplicación',
      'Tiempo por día',
      'Fecha de retorno',
      'Comentarios',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'Código de empleado': yup.object().required(),
      'Tipo de vacaciones': yup.string().required(),
      'Fecha desde': yup.date().required(),
      'Cantidad de días': yup.string().required(),
      'Fecha hasta': yup.date(),
      'Fecha desde de aplicación': yup.date().required(),
      'Fecha hasta de aplicación': yup.date(),
      'Tiempo por día': yup.string().required(),
      'Fecha de retorno': yup.date().nullable(true),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let initialDay = moment(formData["Fecha desde"], "DD/MM/YYYY");
      // Si tengo habilitado lo de parametros generales, no puedo arrancar las vacaciones en dia no habil
      if (!startHolidaysOnNonWorkingDay && !initialDay.isBusinessDay()) {
        dispatch(openSnackbarAction({ duration: null, msg: "La fecha de inicio o fecha desde, no puede ser un día Sábado, Domingo o Feriado", severity: "error" }));
        return;
      }

      if (isCreate) {
        const dataToSend = {
          ...formData,
          'Código de empleado': formData['Código de empleado'].key,
          'Fecha desde': formatDateYYYYMMDD(formData['Fecha desde']),
          'Fecha hasta': formatDateYYYYMMDD(formData['Fecha hasta']),
          'Fecha desde de aplicación': formatDateYYYYMMDD(formData['Fecha desde de aplicación']),
          'Fecha hasta de aplicación': formatDateYYYYMMDD(formData['Fecha hasta de aplicación']),
          'Fecha de retorno': formatDateYYYYMMDD(formData['Fecha de retorno']),
        };

        createCallback(dataToSend);
        return true;
      }

      modifyCallback({ 'Comentarios': formData['Comentarios'], internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const calculateEndDate = (initialDate, employee, vacationTypeCode, amountOfDays) => {
    if (initialDate && employee && vacationTypeCode && amountOfDays) {
      const vacationType = selects.vacationTypes.find(item => item.key === vacationTypeCode);

      moment.updateLocale('es', {
        workingWeekdays: [1, 2, 3, 4, 5]
      });

      if (!vacationType.includeHolidaysAndWeekends) {
        moment.updateLocale('es', {
          holidays: employee.holidays,
          holidayFormat: 'DD/MM/YYYY'
        });

        let initialDay = moment(initialDate, "DD/MM/YYYY");
        // Tengo que avanzar hasta el proximo dia habil, en caso de que este no lo sea
        if (!initialDay.isBusinessDay()) {
          initialDay = initialDay.nextBusinessDay();
        }

        return initialDay.businessAdd(amountOfDays - 1, 'days').toDate();
      } else {
        return moment(initialDate, "DD/MM/YYYY").add(amountOfDays - 1, 'days').toDate();
      }
    }

    return null;
  }

  const calculateEndApplicationDate = (initialDate, startDate, endDate) => {
    if (initialDate && startDate && endDate) {
      const daysToAdd = moment(endDate).diff(moment(startDate), 'days');
      return moment(initialDate, "DD/MM/YYYY").add(daysToAdd, 'days').toDate();
    }

    return null;
  }

  const calculateReturnDate = (initialDate, employee) => {
    if (initialDate && employee) {

      moment.updateLocale('es', {
        workingWeekdays: [1, 2, 3, 4, 5],
        holidays: employee.holidays,
        holidayFormat: 'DD/MM/YYYY'
      });

      let initialDay = moment(initialDate, "DD/MM/YYYY");

      // Tengo que avanzar hasta el proximo dia habil
      initialDay = initialDay.nextBusinessDay();

      return initialDay.toDate();
    }

    return null;
  }

  const setFormValue = (value, fieldName) => {
    // si cambia alguno de los que afecta al calculo de fecha final, recalcular la fecha hasta y fecha hasta de aplicación
    const altersEndDate = ['Tipo de vacaciones', 'Código de empleado', 'Cantidad de días', 'Fecha desde', 'Fecha desde de aplicación'];
    if (altersEndDate.includes(fieldName)) {
      let employee = formData['Código de empleado'];
      let typeCode = formData['Tipo de vacaciones'];
      let amountOfDays = formData['Cantidad de días'];
      let dateFrom = formData['Fecha desde'];
      let appDateFrom = formData['Fecha desde de aplicación'];
      let appDateTo;

      if (fieldName === 'Tipo de vacaciones') {
        typeCode = value;
        amountOfDays = ''; //limpiar cantidad de días
      }

      if (fieldName === 'Código de empleado') {
        employee = value;
        amountOfDays = ''; //limpiar cantidad de días
      }

      if (fieldName === 'Cantidad de días') {
        amountOfDays = value;
      }

      if (fieldName === 'Fecha desde') {
        dateFrom = value;
      }

      if (fieldName === 'Fecha desde de aplicación') {
        appDateFrom = value;
      }

      const dateTo = calculateEndDate(dateFrom, employee, typeCode, amountOfDays);

      if (fieldName === 'Fecha desde') {
        appDateFrom = dateFrom; // si cambia fecha desde, fecha desde de aplicacion va a tener el mismo valor
        appDateTo = dateTo; // si fecha desde y fecha desde de aplicacion son iguales, la fecha hasta tambien
      } else {
        appDateTo = calculateEndApplicationDate(appDateFrom, dateFrom, dateTo);
      }

      setFormData({
        ...formData,
        [fieldName]: value,
        'Fecha hasta': dateTo,
        'Fecha desde de aplicación': appDateFrom,
        'Fecha hasta de aplicación': appDateTo,
        'Fecha de retorno': calculateReturnDate(dateTo, employee),
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
