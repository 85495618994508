import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'unit') {
        return isCreate ? 'D' : data[fieldName];
      }

      if (fieldName === 'motive') {
        return isCreate ? 'N' : data[fieldName];
      }

      if (fieldName === 'classType') {
        return isCreate ? 'I' : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'code',
      'name',
      'type',
      'unit',
      'id',
      'motive',
      'description',
      'classType',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const regex = /^[A-Z]+[A-Z0-9]*/;

    const schema = yup.object().shape({
      'code': yup.string().required().matches(regex),
      'name': yup.string().required(),
      'type': yup.string().required(),
      'unit': yup.string().required(),
      'id': yup.string().required(),
      'motive': yup.string().required(),
      'description': yup.string()
        .when('unit', {
          is: (value) => value === 'D',
          then: yup.string().required()
        }),
      'classType': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      isCreate ? createCallback(formData) : modifyCallback(formData);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    let formattedValue = value;

    if (fieldName === "code") {
      formattedValue = value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return formattedValue;
  }

  const setFormValue = (value, fieldName) => {
    let newFormData = {
      ...formData,
      [fieldName]: formatValue(value, fieldName),
    }

    if (fieldName === "unit" && value === "H") {
      newFormData.type = "H";
    }

    setFormData(newFormData);
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
