import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: "Grupo de ubicación",
            field: "group",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: "Secuencia de aparición del grupo",
            field: "sequence",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: "Activa",
            field: "activeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: "Tipo de KPI",
            field: "kpiTypeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: "Rol de visualización",
            field: "rolString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: "Código de consulta",
            field: "code",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: "Nombre",
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: 'Fecha desde publicación',
            field: "tempStartDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: 'Fecha hasta publicación',
            field: "tempEndDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.kpiDefinition],
        config: {
            headerName: "Texto descriptivo",
            field: "description",
            filter: "agTextColumnFilter",
        }
    },
]