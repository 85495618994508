import React from "react";
import { IMAGES_ENDPOINT_NO_STATIC } from "@icarius-connection/endpoints";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { Typography, Grid } from "@material-ui/core";
import Message from "./Message";

const BoxKPI = (props) => {
  const {
    data,
    color,
    theme,
    handleChartClick,
  } = props;

  const iconForTheme = theme.toLowerCase() === "light" ? data.icon_light : data.icon_dark;

  return (
    <div
      style={{ cursor: data.has_grid ? "pointer" : "" }}
      onClick={data.has_grid ? () => handleChartClick(data.code, data.title) : null}
      className={"container h-100 sm:h-100 relative flex flex-col justify-center items-center"}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <div className="icon-container-kpi">
            {
              iconForTheme ?
                <img alt="" src={IMAGES_ENDPOINT_NO_STATIC + iconForTheme} style={{ maxWidth: "50px", maxHeight: "50px" }} />
                :
                <Message variant="h6" class="errorColor" text={getLocalizedErrorString("invalidAlt")} />
            }
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="flex-col">
            <Typography
              variant={data.data ? "h3" : "h5"}
              style={{
                color: data.data ? color : "red",
                textAlign: "center",
                wordWrap: "break-word",
                fontWeight: data.data ? "normal" : "bold",
              }}
              gutterBottom
            >
              {data.data || 'Sin datos actuales para graficar'}
            </Typography>
            <Message
              class={data.title ? "whiteText" : "errorColor"}
              variant="h5"
              text={data.title || getLocalizedErrorString("invalidTitle")}
            />
            <Message
              class={data.subtitle ? "whiteText" : "errorColor"}
              variant="h5"
              text={data.subtitle || getLocalizedErrorString("invalidSubtitle")}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default BoxKPI;