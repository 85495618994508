import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";

export default [
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("answer"),
            field: "RESPUESTA",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("votos"),
            field: "VOTOS",
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("fType"),
            field: "TIPO DE FUNCIONARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("function"),
            field: "FUNCION",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("category"),
            field: "CATEGORIA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("specialty"),
            field: "ESPECIALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("civilState"),
            field: "ESTADO CIVIL",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("sex"),
            field: "SEXO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("nationality"),
            field: "NACIONALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },

    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("comuna"),
            field: "COMUNA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("region"),
            field: "REGION",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "TIPO DE CONTRATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("laborUnion"),
            field: "SINDICATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config:
        {
            headerName: getLocalizedString("shift"),
            field: "TURNO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.kpiIndicators],
        config: {
            headerName: getLocalizedString("contratista"),
            field: "CONTRATISTA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        },
    },
]