import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getKPIdefinitionAPI,
  createKPIdefinitionAPI,
  modifyKPIdefinitionAPI,
  deleteKPIdefinitionAPI,
  duplicateKPIdefinitionAPI
} from "@icarius-connection/api";
import { getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de una definición que comparta el mismo código o nombre";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getKPIDefinitionAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getKPIdefinitionAPI();
    
    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let kpiTypes = response.data && response.data.kpiTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: { rows, dataTypes, roleTypes, activeTypes, kpiTypes, dateFormat }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createKPIDefinitionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createKPIdefinitionAPI(data);

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let kpiTypes = response.data && response.data.kpiTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { rows, dataTypes, roleTypes, activeTypes, kpiTypes, dateFormat }
    });
    dispatch(openSnackbarAction({ msg: "Definición de KPI creada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyKPIDefinitionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyKPIdefinitionAPI(data);

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let kpiTypes = response.data && response.data.kpiTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { rows, dataTypes, roleTypes, activeTypes, kpiTypes, dateFormat }
    });
    dispatch(openSnackbarAction({ msg: "Definición de KPI modificada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteKPIDefinitionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteKPIdefinitionAPI(data);

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let kpiTypes = response.data && response.data.kpiTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { rows, dataTypes, roleTypes, activeTypes, kpiTypes, dateFormat }
    });
    dispatch(openSnackbarAction({ msg: "Definición de KPI eliminada con éxito", severity: "success" }));
    return response;
  } catch (e) {    
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    errorHandler(e, dispatch);
  }
};

export const duplicateKPIDefinitionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateKPIdefinitionAPI(data);

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let kpiTypes = response.data && response.data.kpiTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { rows, dataTypes, roleTypes, activeTypes, kpiTypes, dateFormat }
    });
    dispatch(openSnackbarAction({ msg: "Definición de KPI duplicada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

