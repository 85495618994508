import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;
export const getLocale = store => store[NAME].locale;
export const getDateFormat = store => store[NAME].dateFormat;

export const getGroupsToFilter = store => store[NAME].groupsToFilter;
export const getKpiIndicators = store => store[NAME].kpiIndicators || [];

export const getGridData = store => store[NAME].gridData || [];
export const getGridTypes = store => store[NAME].gridTypes || [];

export const getFirstLevelData = store => store[NAME].firstLevelData;
export const getSecondLevelData = store => store[NAME].secondLevelData;
export const getThirdLevelData = store => store[NAME].thirdLevelData;
