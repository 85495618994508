import NAME from "./constants";

export const RESET_KPI_INDICATORS = NAME + "/RESET_KPI_INDICATORS";

export const GET_KPI_INDICATORS = NAME + "/GET_KPI_INDICATORS";
export const GET_KPI_INDICATORS_FULFILLED = NAME + "/GET_KPI_INDICATORS_FULFILLED";
export const GET_KPI_INDICATORS_REJECTED = NAME + "/GET_KPI_INDICATORS_REJECTED";

export const GET_LEVEL_DATA = NAME + "/GET_LEVEL_DATA";
export const GET_LEVEL_DATA_FULFILLED = NAME + "/GET_LEVEL_DATA_FULFILLED";
export const GET_LEVEL_DATA_REJECTED = NAME + "/GET_LEVEL_DATA_REJECTED";

export const GO_BACK_ONE_LEVEL = NAME + "/GO_BACK_ONE_LEVEL";

export const GET_KPI_GRID_DATA = NAME + "/GET_KPI_GRID_DATA";
export const GET_KPI_GRID_DATA_FULFILLED = NAME + "/GET_KPI_GRID_DATA_FULFILLED";
export const GET_KPI_GRID_DATA_REJECTED = NAME + "/GET_KPI_GRID_DATA_REJECTED";