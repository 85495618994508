import React from "react";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import Speedometer from "./speedometer";
import BarChart from "./BarChart";
import BoxKPI from "./boxkpi";
import Message from "./Message";

export const getKPIComponent = (getKPIAdditionalDataAction, chart, theme, color, width, handleChartClick, actualLevel, firstLevelData) => {
  let child, cols;

  switch (chart.type) {
    case "CK": {
      child = (
        <Speedometer
          handleChartClick={handleChartClick}
          theme={theme}
          data={chart}
        />
      );
      cols = width > 1300 ? 1 : 3;
      break;
    }
    case "BX": {
      cols = width > 1300 ? 1 : 3;
      child = (
        <BoxKPI
          handleChartClick={handleChartClick}
          color={color}
          theme={theme.theme}
          data={chart}
        />
      );
      break;
    }
    case "GH": // grafico horizontal
    case "GO": //grafico polar
    case "GL": //grafico linea
    case "GR": //grafico radar
    case "GP":
    case "GV": {
      let title = "";
      let subtitle = "";
      let dataToRender;
      const hasData = Object.keys(chart.data).length;

      if (hasData) {
        let chartType;
        switch (chart.type) {
          case "GO":
            chartType = "polarArea";
            break;
          case "GL":
            chartType = "line";
            break;
          case "GR":
            chartType = "radar";
            break;
          case "GP":
            chartType = "pie";
            break;
          case "GV":
            chartType = "bar";
            break;
          case "GH":
            chartType = "horizontalBar";
            break;
          default:
            chartType = "bar";
            break;
        }

        dataToRender = (
          <BarChart
            data={chart}
            type={chartType}
            color={color}
            theme={theme}
            actualLevel={actualLevel}
            firstLevelData={firstLevelData}
            getKPIAdditionalDataAction={getKPIAdditionalDataAction}
            handleChartClick={handleChartClick}
          />
        );
      } else {
        title = chart.title ? <Message class="whiteText" text={chart.title} /> : <Message class="errorColor" text={getLocalizedErrorString("invalidTitle")} />;
        subtitle = chart.subtitle ? <Message class="whiteText" text={chart.subtitle} /> : "";
        dataToRender = <Message class="errorColor" text={'Sin datos actuales para graficar'} />;
      }

      cols = width > 1300 ? (chart.type === "GH" ? 2 : 1) : 3;
      child = (
        <div
          style={!hasData ? {
            height: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          } : {}}
        >
          <div>{title}</div>
          <div>{subtitle}</div>
          <div
            style={!hasData ? {
              height: 250,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            } : {}}
          >
            {dataToRender}
          </div>
        </div>
      );
      break;
    }
    default: break;
  }

  return {
    child,
    cols,
  };
}