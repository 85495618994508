import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading, getData, getStartHolidaysOnNonWorkingDay, getAllowHalfDayHolidays, getAdditionalLegalDays,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getIssueVacationsAction,
  deleteIssueVacationAction,
  downloadStructureAction,
  generateFileFromIssueVacationAction,
} from "../actions";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { getEmployeeVacationsAction } from "@icarius-pages/vacations/actions";
import {
  getIsLoading,
  getEmployeeMiscData,
  getDateFormat,
  getEmployeeDebitData,
  getEmployeeCreditData,
  getVacationsSchema,
} from "@icarius-pages/vacations/selectors";
import { getUserData } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useIssueVacations = (groupCode) => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [employeeVacationsDialogIsOpen, setEmployeeVacationsDialogIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null); //seleccionado para editar o borrar
  const [employeeCode, setEmployeeCode] = useState(null); //seleccionado para modal de balance
  const [uploadFileDialogIsOpen, setUploadFileDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const userData = useSelector(getUserData);
  const startHolidaysOnNonWorkingDay = useSelector(getStartHolidaysOnNonWorkingDay);
  const allowHalfDayHolidays = useSelector(getAllowHalfDayHolidays);
  const additionalLegalDays = useSelector(getAdditionalLegalDays);

  const employeeVacationsDialogIsLoading = useSelector(getIsLoading);
  const dateFormat = useSelector(getDateFormat);
  const debitData = useSelector(getEmployeeDebitData);
  const creditData = useSelector(getEmployeeCreditData);
  const miscData = useSelector(getEmployeeMiscData);
  const vacationsSchema = useSelector(getVacationsSchema);

  const employeeVacationsDialogData = {
    isLoading: employeeVacationsDialogIsLoading,
    dateFormat,
    debitData,
    creditData,
    miscData,
    vacationsSchema,
  }

  useEffect(() => {
    dispatch(getIssueVacationsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, groupCode])

  const handleGenerateFile = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    dispatch(generateFileFromIssueVacationAction({ internalCodes: selectedRows.map(item => item.internalCode) }))
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (userData.level === "M" && selectedRows[0]['Código de empleado'] === userData.code) {
      dispatch(openSnackbarAction({ msg: 'No puede eliminar sus propias vacaciones', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar las vacaciones?",
      onConfirm: () => handleDelete(selectedRows[0]),
    }));
  }

  const handleOpenEmployeeVacationsDialog = (gridRef, code) => {
    let selectedRows;

    if (!code) {
      selectedRows = gridRef.api.getSelectedRows();
      if (selectedRows.length !== 1) {
        dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
        return;
      }
    } else {
      selectedRows = [{
        "Código de empleado": code,
      }]
    }

    dispatch(getEmployeeVacationsAction(userData.level, selectedRows[0]["Código de empleado"]))
      .then((res) => {
        if (res?.status === 200) {
          setEmployeeCode(selectedRows[0]["Código de empleado"]);
          setEmployeeVacationsDialogIsOpen(true);
        }
      })
  }

  const handleCloseEmployeeVacationsDialog = () => {
    setEmployeeVacationsDialogIsOpen(false);
    setEmployeeCode(null);
  }

  const handleOpenCreateDialog = () => {
    setSelectedData(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (userData.level === "M" && selectedRows[0]['Código de empleado'] === userData.code) {
      dispatch(openSnackbarAction({ msg: 'No puede editar sus propias vacaciones', severity: "warning" }));
      return;
    }

    setSelectedData(selectedRows[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleDelete = (document) => {
    const dataToSend = {
      ...document,
      'Fecha desde': formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha desde'])),
      'Fecha hasta': formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha hasta'])),
      'Fecha desde de aplicación': formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha desde de aplicación'])),
      'Fecha hasta de aplicación': formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha hasta de aplicación'])),
      'Fecha de retorno': document['Fecha de retorno'] ? formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha de retorno'])) : null,
    };
    dispatch(deleteIssueVacationAction(dataToSend));
  }

  const handleDownloadStructure = () => {
    dispatch(downloadStructureAction());
  }

  const handleOpenUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(true);
  }

  const handleCloseUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(false);
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedData,
    employeeCode,
    uploadFileDialogIsOpen,
    employeeVacationsDialogIsOpen,
  }

  const handlers = {
    handleDelete,
    handleGenerateFile,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleDownloadStructure,
    handleOpenUploadFileDialogIsOpen,
    handleCloseUploadFileDialogIsOpen,
    handleOpenEmployeeVacationsDialog,
    handleCloseEmployeeVacationsDialog,
  }

  return {
    data,
    isLoading,
    state,
    handlers,
    employeeVacationsDialogData,
    startHolidaysOnNonWorkingDay,
    allowHalfDayHolidays,
    additionalLegalDays,
  }
}

export default useIssueVacations;