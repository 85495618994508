import React from "react";
import {
  Typography,
  GridList,
  GridListTile,
  Grid,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import useGetScreenWidth from "@icarius-utils/hooks/useGetScreenWidth";
import { getKPIComponent } from "./getKPIComponent";
import Container from "./container";

const StyledGridList = withStyles({
  root: {
    width: "100%",
  },
})(GridList);

const Charts = (props) => {

  const {
    individual,
    data,
    groupsToFilter,
    theme,
    color,
    handleChartClick,
    favouriteList,
    showOnlyFavourites,
    handleChangeFavourite,
    handleGoBack,
    firstLevelData,
    secondLevelData,
    thirdLevelData,
    getKPIAdditionalDataAction,
  } = props;

  const width = useGetScreenWidth();

  if (!data || !Object.keys(data).length) return null;

  return Object.keys(data).map((group, index) => {
    if (groupsToFilter?.includes(group.toLocaleLowerCase())) return null;
    if (showOnlyFavourites && data[group].every((kpi) => !favouriteList?.includes(kpi.code))) return null;

    return (
      <div
        key={index}
        style={{ width: "100%" }}
        className="container relative p-5 sm:p-5 flex flex-row justify-between items-center"
      >
        <section style={{ width: "100%" }}>
          {
            !individual &&
            <>
              <Grid container>
                <Grid item xs={12} md={9}>
                  <Typography className={"title whiteText"} variant={"h6"}>
                    {`${group} (${data[group].length})`}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ background: color, height: 2 }} />
            </>
          }
          <StyledGridList cellHeight={430} cols={3} key={index}>
            {
              data?.[group].map((chart, index) => {

                const ownFirstLevelData = firstLevelData?.find((item) => item.code === chart.code) || {};
                const ownSecondLevelData = secondLevelData?.find((item) => item.code === chart.code) || {};
                const ownThirdLevelData = thirdLevelData?.find((item) => item.code === chart.code) || {};

                const currentLevel = !Object.keys(ownSecondLevelData).length ? 1 : !Object.keys(ownThirdLevelData).length ? 2 : 3;

                const { child, cols } = getKPIComponent(
                  getKPIAdditionalDataAction,
                  chart,
                  theme,
                  color,
                  width,
                  handleChartClick,
                  currentLevel,
                  ownFirstLevelData,
                );

                const isFavourite = favouriteList?.includes(chart.code);
                if (showOnlyFavourites && !favouriteList.includes(chart.code)) return null;

                return (
                  <GridListTile key={index} cols={individual ? 3 : cols}>
                    <Container
                      code={chart.code}
                      currentLevel={currentLevel}
                      theme={theme}
                      isFavourite={isFavourite}
                      handleChangeFavourite={handleChangeFavourite}
                      handleGoBack={handleGoBack}
                    >
                      {child}
                    </Container>
                  </GridListTile>
                )
              })
            }
          </StyledGridList>
        </section>
      </div>
    );
  });
}

export default Charts;