import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const DELETE = NAME + "/DELETE";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";

export const CREATE = NAME + "/CREATE";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";

export const MODIFY = NAME + "/MODIFY";
export const MODIFY_REJECTED = NAME + "/MODIFY_REJECTED";
export const MODIFY_FULFILLED = NAME + "/MODIFY_FULFILLED";

export const DOWNLOAD = NAME + "/DOWNLOAD";
export const DOWNLOAD_REJECTED = NAME + "/DOWNLOAD_REJECTED";
export const DOWNLOAD_FULFILLED = NAME + "/DOWNLOAD_FULFILLED";

export const UPLOAD = NAME + "/UPLOAD";
export const UPLOAD_REJECTED = NAME + "/UPLOAD_REJECTED";
export const UPLOAD_FULFILLED = NAME + "/UPLOAD_FULFILLED";

export const GET_BALANCE = NAME + "/GET_BALANCE";
export const GET_BALANCE_REJECTED = NAME + "/GET_BALANCE_REJECTED";
export const GET_BALANCE_FULFILLED = NAME + "/GET_BALANCE_FULFILLED";

export const CREATE_BALANCE = NAME + "/CREATE_BALANCE";
export const CREATE_BALANCE_REJECTED = NAME + "/CREATE_BALANCE_REJECTED";
export const CREATE_BALANCE_FULFILLED = NAME + "/CREATE_BALANCE_FULFILLED";

export const DELETE_BALANCE = NAME + "/DELETE_BALANCE";
export const DELETE_BALANCE_REJECTED = NAME + "/DELETE_BALANCE_REJECTED";
export const DELETE_BALANCE_FULFILLED = NAME + "/DELETE_BALANCE_FULFILLED";

export const GENERATE = NAME + "/GENERATE";
export const GENERATE_REJECTED = NAME + "/GENERATE_REJECTED";
export const GENERATE_FULFILLED = NAME + "/GENERATE_FULFILLED";