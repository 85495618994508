import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,  
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createPermissionTypeAction, modifyPermissionTypeAction } from "@icarius-pages/issueTypesSettings/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, dataArrays, handleClose } = props;
  const dispatch = useDispatch();

  const { absenceCodes, qualifications, periods } = dataArrays;

  const create = (dataToSend) => {
    dispatch(createPermissionTypeAction(dataToSend))
    .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (modifyData) => {
    const dataToSend = { ...modifyData, internalCode: data.internalCode };
    dispatch(modifyPermissionTypeAction(dataToSend))
    .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}            
            label={"Código"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={6} style={gridStyle}> {/* nombre */}
          <TextField    
            required
            fullWidth
            margin={"none"}            
            label={"Nombre"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>    
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* absenceCode */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-absenceCode-${data ? data.code : "new"}`}>{"Código de inasistencias"}</InputLabel>
            <Select      
              disabled={formData.unit === "H"}  
              value={formData.absenceCode}
              labelId={`label-absenceCode-${data ? data.code : "new"}`}
              id={`select-absenceCode-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "absenceCode")}
              margin={"none"}
            >
              {
                absenceCodes && absenceCodes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* qualification  */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-qualification-${data ? data.code : "new"}`}>{"Calificación"}</InputLabel>
            <Select      
              disabled={formData.unit === "H"}  
              value={formData.qualification}
              labelId={`label-qualification-${data ? data.code : "new"}`}
              id={`select-qualification-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "qualification")}
              margin={"none"}
            >
              {
                qualifications && qualifications.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Período aplicable  */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-period-${data ? data.code : "new"}`}>{"Período aplicable"}</InputLabel>
            <Select      
              disabled={formData.unit === "H"}  
              value={formData.period}
              labelId={`label-period-${data ? data.code : "new"}`}
              id={`select-period-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "period")}
              margin={"none"}
            >
              {
                periods && periods.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* quantity */}
          <TextField
            required
            type="number"
            fullWidth
            label={"Cantidad para el período"}
            margin={"none"}
            value={formData.quantity}
            onChange={(e) => setFormValue(e.target.value.substring(0, 8), "quantity")}
            inputProps={{ min: 0, max: 99, maxLength: 2 }}
            onBlur={(e) => e.target.value = e.target.value.substring(0, 2)}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>        
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>      
    </Grid>
  );
}

export default Form;
