import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData, getMatrixData, getYearList, getIssueList } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { getIssueSummaryAction, getIssueSummaryMatrixAction } from "../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import { getUserData, getManagePermissions, getManageVacations } from "src/app/selectors";

const useIssueSummary = (code) => {

  const history = useHistory();

  const [selectedRow, setSelectedRow] = useState(null);
  const [matrixDialogIsOpen, setMatrixDialogIsOpen] = useState(false);

  const dispatch = useDispatch();

  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const matrixData = useSelector(getMatrixData);
  const yearList = useSelector(getYearList);
  const issueList = useSelector(getIssueList);
  const managePermissions = useSelector(getManagePermissions)
  const manageVacations = useSelector(getManageVacations)
  const userData = useSelector(getUserData);

  useEffect(() => {
    dispatch(getIssueSummaryAction(code));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, code])

  const handleOpenMatrixDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un único registro", severity: "warning" }));
      return;
    }

    dispatch(getIssueSummaryMatrixAction(selectedRows.map(item => item['Código de empleado'])[0]))
      .then(resp => {
        if (resp && resp.status === 200) {
          setSelectedRow(selectedRows[0]);
          setMatrixDialogIsOpen(true);
        }
      })
  }

  const handleCloseMatrixDialog = () => {
    setMatrixDialogIsOpen(false);
    setSelectedRow(null);
  }

  const handleOpenCalendarDialog = (gridRef) => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una fila", severity: "warning" }));
      return;
    }

    const stateToSend = {
      date: formatDate(createDateFromDDMMYYYY((selectedRows[0]['Fecha desde']))),
      code: selectedRows[0]['Código de empleado'],
      from: paths.issueSummary,
    };

    history.push({
      pathname: paths.myPeopleSchedule,
      state: stateToSend,
    });
  }

  const handleRowClick = (row) => {
    const stateToSend = { from: paths.issueSummary, name: row.data['Apellido y nombres'], code: row.data['Código de empleado'], date: row.data['Fecha desde'] };

    if (userData.level !== "E" && row.data['Código de empleado'] === userData.code) {
      dispatch(openSnackbarAction({ msg: "No puede gestionar la modificación de su propia información", severity: "warning" }));
      return;
    }

    switch (row.data["Origen"]) {
      case 'Vacaciones':
        if (manageVacations) {
          history.push({
            pathname: paths.issueVacations,
            state: stateToSend
          });
        } else {
          dispatch(openSnackbarAction({ msg: "No posee acceso a la gestión de vacaciones", severity: "warning" }));
        }
        break;
      case 'Inasistencias':
        history.push({
          pathname: paths.issueAbsences,
          state: stateToSend,
        });
        break;
      case 'Permisos':
        if (managePermissions) {
          history.push({
            pathname: paths.issuePermissions,
            state: stateToSend,
          });
        } else {
          dispatch(openSnackbarAction({ msg: "No posee acceso a la gestión de permisos", severity: "warning" }));
        }
        break;
      case 'Licencias':
        if (userData.level === "E") {
          history.push({
            pathname: paths.issueLicences,
            state: stateToSend,
          });
        } else {
          dispatch(openSnackbarAction({ msg: "No posee acceso a la gestión de licencias", severity: "warning" }));
        }
        break;
      default: break;
    }
  }

  return {
    isLoading,
    data,
    selectedRow,
    matrixData,
    yearList,
    issueList,
    matrixDialogIsOpen,
    handleRowClick,
    handleOpenMatrixDialog,
    handleCloseMatrixDialog,
    handleOpenCalendarDialog,
  }
}

export default useIssueSummary;