import { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { deleteKPIDefinitionAction, duplicateKPIDefinitionAction } from "../actions";
import useFilterDialog from "@icarius-common/filtersDialog/components/useFilterDialog";

const useABM = () => {

  const dispatch = useDispatch();

  const { filtersDialogIsOpen, setFiltersDialogIsOpen } = useFilterDialog();
  
  const [ duplicateDialogIsOpen, setDuplicateDialogIsOpen ] = useState(false);
  const [ deleteDialogIsOpen, setDeleteDialogIsOpen ] = useState(false);
  const [ createEditDialogIsOpen, setCreateEditDialogIsOpen ] = useState(false);
  const [ codeToDelete, setCodeToDelete ] = useState(null);  
  const [ selectedItem, setSelectedItem ] = useState(null);

  const handleOpenDuplicateDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setDuplicateDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseDuplicateDialog = () => {
    setDuplicateDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenCreateDialog = () => {
    setCreateEditDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setCreateEditDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseEditDialog = () => {
    setCreateEditDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenFilterDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setFiltersDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  }

  const handleCloseFilterDialog = () => {    
    setFiltersDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setDeleteDialogIsOpen(true);
    setCodeToDelete(selectedRows.map(item => item.code)[0]);
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogIsOpen(false);
    setCodeToDelete(null);
  }

  const handleDelete = () => {
    dispatch(deleteKPIDefinitionAction({ code: codeToDelete }))
    .then(() => {
      handleCloseDeleteDialog();
    })
  }

  const handleDuplicate = (data) => {
    dispatch(duplicateKPIDefinitionAction(data))
    .then(() => {
      handleCloseDuplicateDialog();
    })
  }

  return {
    filtersDialogIsOpen,
    deleteDialogIsOpen,
    createEditDialogIsOpen,
    duplicateDialogIsOpen,
    selectedItem,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
    handleDelete,
    handleDuplicate
  };
}

export default useABM;
