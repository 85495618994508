import React, { useRef, useEffect, useMemo } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import CreateEditDialog from "./dialogs/createEditDialog/index";
import useAutomaticProcesses from "@icarius-common/automaticProcesses/useAutomaticProcesses";
import ResultGridDialog from "@icarius-common/automaticProcesses/components/resultGridDialog";
import PickTemplateDialog from "@icarius-pages/employeesProcesses/components/dialogs/pickTemplateDialog";
import { FlightTakeoffIcon, PrintIcon, VisibilityIcon } from "@icarius-icons/index";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getUserRole } from "src/app/selectors";
import EmployeeVacationsDialog from "@icarius-pages/vacations/components/employeeVacationsDialog";
import { useHistory, useLocation } from "react-router-dom";
import SelectProcessDialog from "@icarius-common/automaticProcesses/components/selectProcessDialog";
import useIssueVacations from "./useIssueVacations";
import UploadFileDialog from "./dialogs/uploadFileDialog";

const IssueVacations = () => {

  const dispatch = useDispatch();
  const role = useSelector(getUserRole);

  const location = useLocation();
  const history = useHistory();

  const ownGridRef = useRef();
  const hasSetFilters = useRef(false);

  const {
    data,
    isLoading,
    state,
    handlers,
    employeeVacationsDialogData,
    startHolidaysOnNonWorkingDay,
    allowHalfDayHolidays,
    additionalLegalDays,
  } = useIssueVacations();

  const {
    isExecution,
    automaticProcessesIsLoading,
    automaticProcessesIsEnabled,
    processList,
    resultData,
    resultDialogIsOpen,
    pickTemplateDialogIsOpen,
    processSelectionDialogIsOpen,
    executeProcess,
    handleExecute,
    viewResult,
    handleViewResult,
    handleCloseProcessDialogSelection,
    handleCloseResultDialog,
    handleOpenPickTemplateDialog,
    handleClosePickTemplateDialog,
    handleGenerateDocumentsGivenTemplate,
  } = useAutomaticProcesses(role === "M");

  useEffect(() => {
    if (data && ownGridRef.current && location?.state && !Boolean(hasSetFilters.current)) {
      ownGridRef.current.api.setFilterModel({
        "Fecha desde": { type: 'iguales', filter: location?.state.date },
      })

      hasSetFilters.current = true;
    }
  }, [data, location])

  const handleValidateAndExecute = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    const employeeCodes = selectedRows.map(item => item["Código de empleado"]);
    handleExecute(employeeCodes);
  }

  const handleValidateAndViewResult = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    handleViewResult(selectedRows[0], selectedRows[0]["Código de empleado"]);
  }

  const executeItem = (gridRef) => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={`Calcular${processList.length === 1 ? `: ${processList[0].value}` : ' proceso'}`}
      onClick={() => handleValidateAndExecute(gridRef)}
      type={"execute"}
    />
  )

  const generateItem = (gridRef) => (
    <CustomIconButton
      title={'Generar comprobante'}
      onClick={() => handlers.handleGenerateFile(gridRef)}
    >
      <PrintIcon />
    </CustomIconButton>
  )

  const viewResultItem = (gridRef) => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={"Ver resultado"}
      onClick={() => handleValidateAndViewResult(gridRef)}
    >
      <VisibilityIcon />
    </CustomIconButton>
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const employeeVacationsButton = (gridRef) => (
    <CustomIconButton
      title={'Ver cta. cte. de vacaciones'}
      onClick={() => handlers.handleOpenEmployeeVacationsDialog(gridRef)}
    >
      <FlightTakeoffIcon />
    </CustomIconButton>
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const exportButton = () =>
    <CustomIconButton
      title={"Exportar plantilla para importación"}
      onClick={handlers.handleDownloadStructure}
      type={"download"}
    />

  const importButton = () =>
    <CustomIconButton
      title={"Importar vacaciones"}
      onClick={handlers.handleOpenUploadFileDialogIsOpen}
      type={"upload"}
    />

  const goBackButton = () => (
    <CustomIconButton
      title={'Volver'}
      type={'goBack'}
      onClick={() => history.push(Boolean(location?.state?.from) ? location?.state.from : paths.issueManagementScreenSelector)}
    />
  )

  const isLoadingSomething = isLoading || automaticProcessesIsLoading || employeeVacationsDialogData.isLoading;

  const filteredData = useMemo(() => {
    if (!data) return [];
    if (!Boolean(location?.state?.code)) return data;
    return data.filter(item => item['Código de empleado'] === location.state?.code);
  }, [data, location])

  const title = `Gestión de vacaciones${Boolean(location?.state?.name) ? ` - ${location.state.name}` : ''}`;

  return (
    <CommonPage
      isLoading={isLoadingSomething}
      title={title}
      gridTitle={title}
      columnDefPage={paths.issueVacations}
      rowData={filteredData}
      menuItems={[
        goBackButton,
        createButton,
        modifyButton,
        deleteButton,
        exportButton,
        importButton,
        generateItem,
        executeItem,
        viewResultItem,
        employeeVacationsButton,
      ]}
      hasExpand
      hasHelp
      ownGridRef={ownGridRef}
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          loading={isLoadingSomething}
          data={state.selectedData}
          handleClose={handlers.handleCloseCreateEditDialog}
          handleOpenBalanceDialog={handlers.handleOpenEmployeeVacationsDialog}
          presetEmployee={location?.state?.code}
          startHolidaysOnNonWorkingDay={startHolidaysOnNonWorkingDay}
          allowHalfDayHolidays={allowHalfDayHolidays}
          additionalLegalDays={additionalLegalDays}
        />
      }
      {
        processSelectionDialogIsOpen &&
        <SelectProcessDialog
          open={processSelectionDialogIsOpen}
          title={isExecution ? "Selección de proceso a ejecutar" : "Selección de proceso a consultar"}
          processList={processList}
          handleClose={handleCloseProcessDialogSelection}
          handleExecute={isExecution ? executeProcess : viewResult}
        />
      }
      {
        resultDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
        <ResultGridDialog
          open={resultDialogIsOpen}
          templates={resultData.templates}
          process={resultData.process}
          employeeName={resultData.employee['Apellido y nombres']}
          handleClose={handleCloseResultDialog}
          handleOpenPickTemplateDialog={handleOpenPickTemplateDialog}
          handleGenerateDocumentsGivenTemplate={handleGenerateDocumentsGivenTemplate}
        />
      }
      {
        resultData?.templates?.length > 0 && pickTemplateDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
        <PickTemplateDialog
          open={pickTemplateDialogIsOpen}
          templates={resultData.templates}
          handleConfirm={handleGenerateDocumentsGivenTemplate}
          handleClose={handleClosePickTemplateDialog}
        />
      }
      {
        state.uploadFileDialogIsOpen &&
        <UploadFileDialog
          open={state.uploadFileDialogIsOpen}
          isLoading={isLoading}
          handleClose={handlers.handleCloseUploadFileDialogIsOpen}
        />
      }
      {
        state.employeeVacationsDialogIsOpen && !isLoadingSomething &&
        <EmployeeVacationsDialog
          open={state.employeeVacationsDialogIsOpen}
          creditData={employeeVacationsDialogData.creditData}
          debitData={employeeVacationsDialogData.debitData}
          miscData={employeeVacationsDialogData.miscData}
          vacationsSchema={employeeVacationsDialogData.vacationsSchema}
          dateFormat={employeeVacationsDialogData.dateFormat}
          employeeCode={state.employeeCode}
          handleClose={handlers.handleCloseEmployeeVacationsDialog}
        />
      }
    </CommonPage>
  )
}

export default IssueVacations;