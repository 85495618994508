import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const GET_MATRIX = NAME + "/GET_MATRIX";
export const GET_MATRIX_REJECTED = NAME + "/GET_MATRIX_REJECTED";
export const GET_MATRIX_FULFILLED = NAME + "/GET_MATRIX_FULFILLED";