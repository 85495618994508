import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import { getLocalizedString } from "@icarius-localization/strings";
import { AbsenceIcon, PermissionIcon, LicenceIcon } from "@icarius-icons/";
import paths from "@icarius-localization/paths";

const IssueTypeSettings = () => {

  const data = [
    {
      name: "Inasistencias",
      icon: <AbsenceIcon />,
      path: paths.absenceTypes,
    },
    {
      name: "Permisos",
      icon: <PermissionIcon />,
      path: paths.permissionTypes,
    },
    {
      name: "Licencias médicas",
      icon: <LicenceIcon />,
      path: paths.licenceTypes,
    },
  ];

  return (
    <ScreenSelector
      title={getLocalizedString("issueTypesSettings")}
      data={data}
    />
  );
}

export default IssueTypeSettings;
