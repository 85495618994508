import React from "react";
import { Chart } from "react-chartjs-2";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { formatNumberExactDecimals, splitIntoLines } from "@icarius-utils/format";
import { hexToRgb, hexToRgbArray, borneo, material } from "@icarius-utils/colors";
import { connect } from "react-redux";

const opacityChart = 0.5;

class VerticalBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.update();
  }

  componentDidMount() {
    const {
      dispatch,
      color,
      theme,
      type,
      data,
      actualLevel,
      firstLevelData,
      getKPIAdditionalDataAction,
    } = this.props;

    let chartData;
    const chunksTitle = data.title ? splitIntoLines(data.title, 50) : [""];
    const chunksSubtitle = data.subtitle ? splitIntoLines(data.subtitle, 50) : [""];

    switch (data.type) {
      case "GR": //grafico radar
        let labels = data?.data.length && Object.values(data.data[0].data)?.length ? Object.keys(data.data[0].data) : [];

        if (labels) {
          labels = labels.map((item) => splitIntoLines(item, 10));
        }

        let colorsToUse = labels.length > 8 ? material : borneo;

        const dataset = data.data.map((item, index) => {
          return ({
            label: item.label,
            pointHoverRadius: 5,
            pointRadius: 3,
            fontColor: "#00FF00",
            pointHitRadius: 10,
            data: Object.values(item.data),
            borderColor: colorsToUse.strokes[index],
            backgroundColor: hexToRgb(colorsToUse.fills[index], opacityChart),
            borderWidth: 1,
          });
        });

        chartData = {
          labels: labels,
          datasets: dataset,
        };

        break;
      case "GL": //grafico linea
        chartData = {
          labels: Object.keys(data.data),
          datasets: [
            {
              fill: false,
              pointHoverRadius: 5,
              pointRadius: 3,
              fontColor: "#00FF00",
              pointHitRadius: 10,
              borderColor: color,
              backgroundColor: color,
              borderWidth: 1,
              data: Object.values(data.data),
            },
          ],
        };
        break;
      case "GO":
        chartData = {
          labels: Object.keys(data.data),
          datasets: [
            {
              pointHoverRadius: 5,
              pointRadius: 3,
              fontColor: "#00FF00",
              pointHitRadius: 10,
              data: Object.values(data.data),
              borderColor: Object.values(data.data).length > 8 ? material.strokes : borneo.strokes,
              backgroundColor: hexToRgbArray(Object.values(data.data).length > 8 ? material.fills : borneo.fills, opacityChart),
              borderWidth: 1,
            },
          ],
        };
        break;
      case "GP":
      case "GV":
      case "GH":
      default:
        chartData = {
          labels: Object.keys(data.data),
          datasets: [
            {
              pointHoverRadius: 5,
              pointRadius: 3,
              fontColor: "#00FF00",
              pointHitRadius: 10,
              data: Object.values(data.data),
              borderColor: Object.values(data.data).length > 8 ? material.strokes : borneo.strokes,
              backgroundColor: Object.values(data.data).length > 8 ? material.fills : borneo.fills,
              borderWidth: 1,
            },
          ],
        };
        break;
    }

    const optionsRadar = {
      animation: {
        duration: 0
      },
      title: data.title
        ? {
          display: true,
          fontColor: theme.theme === "dark" ? "#FFF" : "black",
          fontSize: 14,
          text: [...chunksTitle, ...chunksSubtitle],
        }
        : {
          display: true,
          fontColor: "red",
          fontSize: 16,
          text: getLocalizedErrorString("invalidTitle"),
        },
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          fontColor: theme.theme === "dark" ? "#FFF" : "black",
        },
      },
      tooltips: {
        titleFontSize: 10,
        bodyFontSize: 10,
        callbacks: {
          label: function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
          },
        },
      },
      scale: {
        ticks: {
          fontColor: theme.theme === "dark" ? "white" : "black",
          showLabelBackdrop: false,
        },
        gridLines: {
          color: theme.theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(1, 1, 1, 0.2)",
        },
        angleLines: {
          color: theme.theme === "dark" ? "white" : "black", // lines radiating from the center
        },
      },
    };

    const optionsPolar = {
      title: data.title
        ? {
          display: true,
          fontColor: theme.theme === "dark" ? "#FFF" : "black",
          fontSize: 14,
          text: [...chunksTitle, ...chunksSubtitle],
        }
        : {
          display: true,
          fontColor: "red",
          fontSize: 16,
          text: getLocalizedErrorString("invalidTitle"),
        },
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          fontColor: theme.theme === "dark" ? "#FFF" : "black",
        },
      },
      tooltips: {
        titleFontSize: 10,
        bodyFontSize: 10,
      },
      scale: {
        ticks: {
          fontColor: theme.theme === "dark" ? "white" : "black",
          showLabelBackdrop: false,
        },
        gridLines: {
          color: theme.theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(1, 1, 1, 0.2)",
        },
      },
    };

    const options = {
      animation: {
        duration: 0
      },
      onClick: function (e) {
        if (
          ["GP", "GV", "GH", "GL"].includes(data.type) &&
          ((actualLevel === 1 && data.has_second_level) || (actualLevel === 2 && data.has_third_level)) &&
          this.getElementsAtEvent(e)?.length
        ) {
          let activePointLabel = null;
          if (type === "line") {
            const chartElement = this.getElementsAtEvent(e)[0];
            activePointLabel = chartElement._xScale.ticks[chartElement._index];
          } else {
            activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
          }

          const { codes, code } = data;
          const category = activePointLabel;
          let categoryCode = "";

          if (codes.hasOwnProperty(category)) {
            categoryCode = codes[category];
          }

          if (actualLevel === 1) {
            dispatch(getKPIAdditionalDataAction(
              {
                code,
                firstLevelCategory: category,
                firstLevelCategoryCode: categoryCode,
                level: actualLevel,
              },
              data
            ));
          } else {
            dispatch(getKPIAdditionalDataAction(
              {
                code,
                firstLevelCategory: firstLevelData?.firstLevelCategory,
                firstLevelCategoryCode: firstLevelData?.firstLevelCategoryCode,
                secondLevelCategory: category,
                secondLevelCategoryCode: categoryCode,
                level: actualLevel,
              },
              data
            ));
          }
        }
      },
      title: data.title
        ? {
          display: true,
          fontColor: theme.theme === "dark" ? "#FFF" : "black",
          fontSize: 14,
          text: [...chunksTitle, ...chunksSubtitle],
        }
        : {
          display: true,
          fontColor: "red",
          fontSize: 16,
          text: getLocalizedErrorString("invalidTitle"),
        },
      maintainAspectRatio: false,
      legend: {
        display: !(type === "horizontalBar" || type === "bar" || type === "line"),
        labels: {
          fontColor: theme.theme === "dark" ? "#FFF" : "black",
        },
      },
      tooltips:
        type === "radar"
          ? {
            titleFontSize: 10,
            bodyFontSize: 10,
            callbacks: {
              label: function (tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
              },
            },
          }
          : {
            callbacks: {
              label: function (tooltipItem, data) {
                const value = data.datasets[0].data[tooltipItem.index] || 0;
                const formattedValue = formatNumberExactDecimals(parseFloat(value), 2);

                // Si es grafico tipo GP
                if (tooltipItem.yLabel === '' && tooltipItem.xLabel === '') {
                  return `${data.labels[tooltipItem.index]}: ${formattedValue}`;
                } else {
                  return formattedValue;
                }
              }
            },
            titleFontSize: 10,
            bodyFontSize: 10,
          },
      scales: (type === "horizontalBar" || type === "bar" || type === "line") ?
        {
          xAxes: [
            {
              ticks: {
                fontColor: theme.theme === "dark" ? "#CCC" : "black",
                beginAtZero: true,
                min: 0
              },
              gridLines: {
                color: theme.theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(1, 1, 1, 0.2)",
                zeroLineColor: "transparent",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: theme.theme === "dark" ? "#CCC" : "black",
                beginAtZero: true,
                min: 0
              },
              gridLines: {
                color: theme.theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(1, 1, 1, 0.2)",
                zeroLineColor: "transparent",
              },
            },
          ],
        }
        : {},
    };

    this.myChart = new Chart(this.canvasRef.current, {
      type: type,
      options: type === "radar" ? optionsRadar : type === "polarArea" ? optionsPolar : options,
      data: chartData,
    });
  }

  render() {
    const {
      data,
      actualLevel,
      handleChartClick,
    } = this.props;

    return (
      <div className="container h-100 sm:h-100 relative flex flex-col justify-center items-center">
        <div className="principal chart-wrapper">
          <div
            style={{ height: 400, cursor: data.has_grid ? "pointer" : "" }}
            className="container  h-100 sm:h-100  p-16 sm:p-24 justify-between items-center"
            onClick={
              (
                data.has_grid &&
                ((!["GP", "GV", "GH", "GL"].includes(data.type)) ||
                  (["GP", "GV", "GH", "GL"].includes(data.type) && actualLevel === 1 && !data.has_second_level) ||
                  (["GP", "GV", "GH", "GL"].includes(data.type) && actualLevel === 2 && !data.has_third_level) ||
                  (["GP", "GV", "GH", "GL"].includes(data.type) && actualLevel === 3 && data.has_grid)
                ))
                ? () => handleChartClick(data.code, data.title)
                : null
            }
          >
            <canvas ref={this.canvasRef} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(VerticalBarChart);