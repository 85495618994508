/* eslint-disable array-callback-return */
import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import {
  getAbsenceTypesSettingsAPI,
  createAbsenceTypesSettingsAPI,
  updateAbsenceTypesSettingsAPI,
  deleteAbsenceTypesSettingsAPI,
  //
  getPermissionTypesSettingsAPI,
  createPermissionTypesSettingsAPI,
  updatePermissionTypesSettingsAPI,
  deletePermissionTypesSettingsAPI,
  //
  getLicenceTypesSettingsAPI,
  createLicenceTypesSettingsAPI,
  updateLicenceTypesSettingsAPI,
  deleteLicenceTypesSettingsAPI
} from "@icarius-connection/api";

const errorHandler = (e, dispatch, type) => {
  let errorString = "";

  const getTypeName = () => {
    switch(type){
      case "ABS": return "inasistencia";
      case "PERM": return "permiso";
      case "LIC": return "licencia médica";
      default: return "";
    }
  }

  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = `No puede existir más de un tipo de ${getTypeName()} que comparta el mismo código o nombre`;
      break;
    case "IN_USE":
      errorString = `No es posible eliminar el tipo de ${getTypeName()} porque está en uso`;
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

//Absence
export const getAbsenceTypesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getAbsenceTypesSettingsAPI();

    let data = response.data && response.data.data;
    let types = response.data && response.data.types;
    let units = response.data && response.data.units;
    let ids = response.data && response.data.ids;
    let motives = response.data && response.data.motives;
    let classList = response.data && response.data.classList;
    let status = response.data && response.data.status;

    const dataArrays = { types, units, ids, motives, classList };

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, dataArrays },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteAbsenceTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteAbsenceTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de inasistencia eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "ABS");
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    return e.response;
  }
};

export const createAbsenceTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createAbsenceTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de inasistencia creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "ABS");
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    
  }
};

export const modifyAbsenceTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateAbsenceTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de inasistencia actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "ABS");
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
  }
};

//Permission
export const getPermissionTypesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getPermissionTypesSettingsAPI();

    let data = response.data && response.data.data;  
    let absenceCodes = response.data && response.data.absenceCodes;
    let qualifications = response.data && response.data.qualifications;
    let periods = response.data && response.data.periods;
    let status = response.data && response.data.status;

    const dataArrays = { absenceCodes, qualifications, periods };

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, dataArrays },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deletePermissionTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deletePermissionTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de permiso eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "PERM");
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    return e.response;
  }
};

export const createPermissionTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createPermissionTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de permiso creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "PERM");
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    
  }
};

export const modifyPermissionTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updatePermissionTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de permiso actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "PERM");
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
  }
};

//Licence
export const getLicenceTypesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getLicenceTypesSettingsAPI();

    let data = response.data && response.data.data;  
    let absenceCodes = response.data && response.data.absenceCodes;
    let types = response.data && response.data.types;
    let status = response.data && response.data.status;

    const dataArrays = { absenceCodes, types };

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, dataArrays },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteLicenceTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteLicenceTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de licencia eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "LIC");
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    return e.response;
  }
};

export const createLicenceTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createLicenceTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de licencia creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "LIC");
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    
  }
};

export const modifyLicenceTypeAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateLicenceTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Tipo de licencia actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, "LIC");
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
  }
};