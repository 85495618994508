import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {
    let initialEmptyData = {
      code: "",
      name: "",
      absenceCode: "",
      qualification: "P",
      period: "-",
      quantity: "0",
    };

    if(!isCreate){
      initialEmptyData = { ...data };
    }

    return initialEmptyData;
  }

  const [ formData, setFormData ] = useState(createInitialData());

  const dataIsValid = () => {
    const { code, name, absenceCode, qualification, period, quantity } = formData;

    const codeRegex = /^[A-Z]+[A-Z0-9]*/;
    
    if(!code || code === "" || !codeRegex.test(code)) return false;
    if(!name || name === "") return false;
    if(!absenceCode || absenceCode === "") return false;
    if(!qualification || qualification === "") return false;
    if(!period || period === "") return false;
    if(quantity === null || quantity === "") return false;    

    return true;
  }

  const submit = () => {
    if(dataIsValid()){
      isCreate ? createCallback(formData) : modifyCallback(formData);
      return true;
    }else{
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    let formattedValue = value;

    if(fieldName === "code"){
      formattedValue = value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    if(fieldName === "quantity"){
      formattedValue = value.length > 0 ? parseInt(value) : "";
    }

    return formattedValue;
  }

  const setFormValue = (value, fieldName) => {
    let newFormData = {
      ...formData,
      [fieldName]: formatValue(value, fieldName),
    }

    setFormData(newFormData);    
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
