import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FavouriteButton from "@icarius-common/favouriteButton/components";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getLocalizedString } from "@icarius-localization/strings";
import { KeyboardBackspaceIcon } from "@icarius-icons";

const ModifiedCardContent = withStyles({
  root: {
    width: "100%",
  },
})(CardContent);

const Container = (props) => {

  const {
    currentLevel,
    children,
    code,
    theme,
    isFavourite,
    handleChangeFavourite,
    handleGoBack,
  } = props;

  const ModifiedCard = withStyles({
    root: {
      marginTop: 10,
      height: 414,
      marginBottom: 10,
      borderColor: theme.theme === "dark" ? "#1c1c1c" : "#cecece",
      borderStyle: "solid",
      borderWidth: "1px",
      backgroundColor: theme.theme === "dark" ? "#303030" : "var(--secondaryBackgroundColor)",
      color: theme.theme === "dark" ? "white" : "black",
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0 0px 0px",
    },
  })(Card);

  return (
    <ModifiedCard>
      <ModifiedCardContent>
        {
          currentLevel === 1 && handleChangeFavourite &&
          <FavouriteButton
            useAbsolutePosition
            type={"KPI"}
            code={code}
            isFavourite={isFavourite}
            handleChangeFavourite={handleChangeFavourite}
          />
        }
        {
          (currentLevel === 2 || currentLevel === 3) &&
          <div style={{ position: 'absolute', top: 20, right: 5, zIndex: 2 }}>
            <CustomIconButton
              title={getLocalizedString("goBack")}
              onClick={() => handleGoBack(code, currentLevel)}
            >
              <KeyboardBackspaceIcon />
            </CustomIconButton>
          </div>
        }
        {children}
      </ModifiedCardContent>
    </ModifiedCard>
  )
}

export default Container;