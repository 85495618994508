import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createKPIDefinitionAction, modifyKPIDefinitionAction } from "../../actions";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { withStyles } from "@material-ui/core/styles";
import { getActiveTypes, getKPITypes, getRoleTypes } from "../../selectors";
import { AddIcon } from "@icarius-icons";
import { getAppColor } from "src/app/selectors";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const gridStyle = { height: 75, padding: "0px 10px" };

const SelectorButton = ({ text, onClick }) => {

  const color = useSelector(getAppColor);

  return (
    <Button
      style={{ margin: 5 }}
      variant={"outlined"}
      disableRipple={true}
      onClick={onClick}>
      <AddIcon htmlColor={color} />
      {text}
    </Button>
  )
}

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const QueryForm = (props) => {

  const { data, handleClose, classes } = props;
  const dispatch = useDispatch();

  const activeTypes = useSelector(getActiveTypes);
  const roleTypes = useSelector(getRoleTypes);
  const kpiTypes = useSelector(getKPITypes);

  const create = (formData) => {
    dispatch(createKPIDefinitionAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(modifyKPIDefinitionAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openSelectorValidationError = () => {
    dispatch(openSnackbarAction({ msg: "Selectores invalidos", severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError, openSelectorValidationError);

  const isGraphicType = ['GH', 'GV', 'GP', 'GO', 'GR', 'GL'].includes(formData.kpiType);
  const isDetailedGraphicType = ['GH', 'GV', 'GP', 'GL'].includes(formData.kpiType);
  const isClockType = 'CK' === formData.kpiType;
  const isBoxType = 'BX' === formData.kpiType;

  return (
    <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={"Codigo de KPI"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Activa */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-active`}>{"Activa"}</InputLabel>
            <Select
              value={formData.active}
              labelId={`label-active`}
              id={`select-active`}
              onChange={(e) => setFormValue(e.target.value, "active")}
              margin={"none"}
            >
              {
                activeTypes && activeTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* group */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Grupo de ubicación"}
            value={formData.group}
            onChange={(e) => setFormValue(e.target.value, "group")}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* secuencia */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Secuencia de aparición'}
            type="number"
            value={formData.sequence}
            onChange={(e) => setFormValue(e.target.value.substring(0, 3), "sequence")}
            inputProps={{ min: 1, max: 999, maxLength: 3 }}
            onBlur={(e) => e.target.value = e.target.value.substring(0, 3)}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* kpi type */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-kpiType`}>{"Tipo de KPI"}</InputLabel>
            <Select
              value={formData.kpiType}
              labelId={`label-kpiType`}
              id={`select-kpiType`}
              onChange={(e) => setFormValue(e.target.value, "kpiType")}
              margin={"none"}
            >
              {
                kpiTypes && kpiTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* description */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Texto descriptivo de KPI"}
            value={formData.description}
            onChange={(e) => setFormValue(e.target.value, "description")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* fecha desde publicacion */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              style={{ marginTop: 8 }}
              required
              fullWidth
              label={'Fecha desde publicación'}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=''
              format="dd/MM/yyyy"
              margin="normal"
              value={formData.tempStartDate}
              onChange={(e) => setFormValue(e, "tempStartDate")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* fecha hasta publicacion */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              style={{ marginTop: 8 }}
              required
              fullWidth
              label={'Fecha hasta publicación'}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=''
              format="dd/MM/yyyy"
              margin="normal"
              value={formData.tempEndDate}
              onChange={(e) => setFormValue(e, "tempEndDate")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* role */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-rol`}>{"Rol de visualización"}</InputLabel>
            <Select
              value={formData.rol}
              labelId={`label-role`}
              id={`select-role`}
              onChange={(e) => setFormValue(e.target.value, "rol")}
              margin={"none"}
            >
              {
                roleTypes && roleTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>

        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql title */}
          <TextField
            fullWidth
            label="SQL para titulo del KPI (Necesario para todos los KPI)"
            variant="outlined"
            multiline
            rows={8}
            margin={"none"}
            value={formData.sqlTitle}
            onChange={(e) => setFormValue(e.target.value, "sqlTitle")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql subtitle */}
          <TextField
            fullWidth
            label="SQL para subtitulo del KPI (Optativo y dependerá de cada KPI)"
            variant="outlined"
            multiline
            rows={8}
            margin={"none"}
            value={formData.sqlSubtitle}
            onChange={(e) => setFormValue(e.target.value, "sqlSubtitle")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>

        {/* SQL Número BOX y CLOCK  */}
        {
          Boolean(isBoxType || isClockType) &&
          <>
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql para numero*/}
              <TextField
                fullWidth
                label="SQL para mostrar un Número dentro del BOX o CLOCK"
                variant="outlined"
                multiline
                rows={25}
                margin={"none"}
                value={formData.sqlNumber}
                onChange={(e) => setFormValue(e.target.value, "sqlNumber")}
                helperText={'Con el valor del Número, se mostrará el Icono relacionado en el BOX o en el valor de la aguja en el CLOCK'}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
              />
            </Grid>
          </>
        }
        {
          Boolean(isClockType) &&
          <>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Valor desde Clock */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor desde Clock'}
                type="number"
                value={formData.clockMinimumValue}
                onChange={(e) => setFormValue(e.target.value, "clockMinimumValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Valor hasta Clock */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor hasta Clock'}
                type="number"
                value={formData.clockMaximumValue}
                onChange={(e) => setFormValue(e.target.value, "clockMaximumValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Valor desde Green */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor desde Green'}
                type="number"
                value={formData.clockMinumumGreenValue}
                onChange={(e) => setFormValue(e.target.value, "clockMinumumGreenValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Valor hasta Green */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor hasta Green'}
                type="number"
                value={formData.clockMaximumGreenValue}
                onChange={(e) => setFormValue(e.target.value, "clockMaximumGreenValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Valor desde Yellow */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor desde Yellow'}
                type="number"
                value={formData.clockMinimumYellowValue}
                onChange={(e) => setFormValue(e.target.value, "clockMinimumYellowValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Valor hasta Yellow */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor hasta Yellow'}
                type="number"
                value={formData.clockMaximumYellowValue}
                onChange={(e) => setFormValue(e.target.value, "clockMaximumYellowValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Valor desde Red */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor desde Red'}
                type="number"
                value={formData.clockMinimumRedValue}
                onChange={(e) => setFormValue(e.target.value, "clockMinimumRedValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Valor hasta Red */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor hasta Red'}
                type="number"
                value={formData.clockMaximumRedValue}
                onChange={(e) => setFormValue(e.target.value, "clockMaximumRedValue")}
              />
            </Grid>
          </>
        }
        {
          Boolean(isBoxType) &&
          <>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Icono 1 BOX */}
              <TextField
                fullWidth
                margin={"none"}
                label={"Icono 1 BOX"}
                value={formData.icon1}
                onChange={(e) => setFormValue(e.target.value, "icon1")}
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Valor desde Icono 1 */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor desde Icono 1'}
                type="number"
                value={formData.icon1MinimumValue}
                onChange={(e) => setFormValue(e.target.value, "icon1MinimumValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Valor hasta Icono 1 */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor hasta Icono 1'}
                type="number"
                value={formData.icon1MaximumValue}
                onChange={(e) => setFormValue(e.target.value, "icon1MaximumValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Icono 2 BOX */}
              <TextField
                fullWidth
                margin={"none"}
                label={"Icono 2 BOX"}
                value={formData.icon2}
                onChange={(e) => setFormValue(e.target.value, "icon2")}
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Valor desde Icono 2 */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor desde Icono 2'}
                type="number"
                value={formData.icon2MinimumValue}
                onChange={(e) => setFormValue(e.target.value, "icon2MinimumValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Valor hasta Icono 2 */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor hasta Icono 2'}
                type="number"
                value={formData.icon2MaximumValue}
                onChange={(e) => setFormValue(e.target.value, "icon2MaximumValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Icono 3 BOX */}
              <TextField
                fullWidth
                margin={"none"}
                label={"Icono 3 BOX"}
                value={formData.icon3}
                onChange={(e) => setFormValue(e.target.value, "icon3")}
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Valor desde Icono 3 */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor desde Icono 3'}
                type="number"
                value={formData.icon3MinimumValue}
                onChange={(e) => setFormValue(e.target.value, "icon3MinimumValue")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Valor hasta Icono 3 */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valor hasta Icono 3'}
                type="number"
                value={formData.icon3MaximumValue}
                onChange={(e) => setFormValue(e.target.value, "icon3MaximumValue")}
              />
            </Grid>
          </>
        }
        {/* FIN SQL Número BOX y CLOCK  */}

        {/* SQL para gráficos  */}
        {
          Boolean(isGraphicType) &&
          <>
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql para graficos */}
              <TextField
                fullWidth
                label="SQL para Gráficos (Horizontal, Vertical, de Torta, de Líneas, Polar o Radar)"
                variant="outlined"
                multiline
                rows={25}
                margin={"none"}
                value={formData.sqlChart}
                onChange={(e) => setFormValue(e.target.value, "sqlChart")}
                helperText={'Para los gráficos de SQL debe seleccionar siempre 2 columnas, la primera de tipo alfanumérico y se utilizará como el nombre de la serie, y la segunda de tipo numérica y se tomará como Valor de la serie, a excepción de los gráficos de Radar, donde podrán ser varias las columnas numéricas (Ideal entre 5 y 10). Si es un gráfico de tortas, horizontal, vertical o de líneas y contiene KPI anidados, incluya una tercer columna alfanumérica que contenga el "Código" de la "Serie" para luego saber en que área del gráfico se hizo click. Dicho código se cargará en la variable #CODE1# y el "Nombre" del área clickeada, 1er columna, en la variable #DESC1#. Si se accede a un 2do gráfico, sucederá lo mismo y se cargarán las variables #CODE2# y #DESC2#.'}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
              />
            </Grid>
          </>
        }
        {/* FIN SQL para gráficos */}

        {/* SQL para detalle de gráficos*/}
        {
          Boolean(isDetailedGraphicType) &&
          <>
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql title */}
              <TextField
                fullWidth
                label="SQL para titulo de Gráficos Nivel 2 (Optativo)"
                variant="outlined"
                multiline
                rows={8}
                margin={"none"}
                value={formData.sqlTitleLevel2}
                onChange={(e) => setFormValue(e.target.value, "sqlTitleLevel2")}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql para graficos nivel 2*/}
              <TextField
                fullWidth
                label="SQL para Gráficos Nivel 2 (Horizontal, Vertical, de Torta o de Líneas)"
                variant="outlined"
                multiline
                rows={25}
                margin={"none"}
                value={formData.sqlChartLevel2}
                onChange={(e) => setFormValue(e.target.value, "sqlChartLevel2")}
                helperText={'Para los gráficos de SQL debe seleccionar siempre 2 columnas, la primera de tipo alfanumérico y se utilizará como el nombre de la serie, y la segunda de tipo numérica y se tomará como Valor de la serie, a excepción de los gráficos de Radar, donde podrán ser varias las columnas numéricas (Ideal entre 5 y 10)'}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql title */}
              <TextField
                fullWidth
                label="SQL para titulo de Gráficos Nivel 3 (Optativo)"
                variant="outlined"
                multiline
                rows={8}
                margin={"none"}
                value={formData.sqlTitleLevel3}
                onChange={(e) => setFormValue(e.target.value, "sqlTitleLevel3")}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql para graficos nivel 2*/}
              <TextField
                fullWidth
                label="SQL para Gráficos Nivel 3 (Horizontal, Vertical, de Torta o de Líneas)"
                variant="outlined"
                multiline
                rows={25}
                margin={"none"}
                value={formData.sqlChartLevel3}
                onChange={(e) => setFormValue(e.target.value, "sqlChartLevel3")}
                helperText={'Para los gráficos de SQL debe seleccionar siempre 2 columnas, la primera de tipo alfanumérico y se utilizará como el nombre de la serie, y la segunda de tipo numérica y se tomará como Valor de la serie, a excepción de los gráficos de Radar, donde podrán ser varias las columnas numéricas (Ideal entre 5 y 10)'}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
              />
            </Grid>
          </>
        }
        {/* FIN SQL para detalle de gráficos*/}

        {/* SQL de visualización  */}
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* SQL de visualización */}
          <TextField
            fullWidth
            label="SQL de visualización"
            variant="outlined"
            multiline
            rows={25}
            margin={"none"}
            value={formData.sqlVisualization}
            onChange={(e) => setFormValue(e.target.value, "sqlVisualization")}
            helperText={'Optativo. Si existe, se mostrará el resultado de este SQL en una grilla cuando se haga click en el KPI. Si es un KPI anidado se mostrará cuando haga click en el último gráfico.'}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "0px 10px" }}> {/* selectores */}
          <TextField
            required={Boolean(formData.sqlVisualization)}
            fullWidth
            helperText={"Para cada columna de SQL, los tipos de selectores pueden ser: Lista,Fecha,Alfa,Num,Mon"}
            margin={"none"}
            label={"Tipo de selector por cada columna del SQL"}
            value={formData.selectors}
            onChange={(e) => setFormValue(e.target.value, "selectors")}
          />
        </Grid>
        <Grid container item alignItems="center" justify="center" xs={12} style={{ marginTop: 10, marginBottom: 20 }}>
          <SelectorButton
            text={"Lista desplegable"}
            onClick={() => setFormValue(`${formData.selectors},Lista`, "selectors")}
          />
          <SelectorButton
            text={"Fecha"}
            onClick={() => setFormValue(`${formData.selectors},Fecha`, "selectors")}
          />
          <SelectorButton
            text={"Alfanumérico"}
            onClick={() => setFormValue(`${formData.selectors},Alfa`, "selectors")}
          />
          <SelectorButton
            text={"Numérico entero"}
            onClick={() => setFormValue(`${formData.selectors},Num`, "selectors")}
          />
          <SelectorButton
            text={"Numérico con decimales"}
            onClick={() => setFormValue(`${formData.selectors},Mon`, "selectors")}
          />
        </Grid>
        {/* FIN SQL de visualizacion */}
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ marginBottom: 35 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(QueryForm);
